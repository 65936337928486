import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from './resource.service';
import { ProgramConfig } from '@model';

@Injectable()
export class ProgramService {
  private programConfig$: Observable<ProgramConfig>;

  apiUrl: string;

  constructor(private programResourceService: ResourceService<ProgramConfig>) {
    this.apiUrl = programResourceService.apiLocation;
  }

  getProgramConfig(): Observable<ProgramConfig> {
    this.programConfig$ = this.programResourceService.get(`programs/config`);

    return this.programConfig$;
  }

  putProgramConfig$(programConfig: ProgramConfig): Observable<ProgramConfig> {
    return this.programResourceService.updatePath(`programs/config`, programConfig);
  }
}
