import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BidDeck, DayAheadMarketConfig, RealTimeMarketConfig, Site } from '@model';
import { ResourceService } from 'src/app/core/service/resource.service';

@Injectable({
  providedIn: 'root',
})
export class MarketsConfigService {
  private readonly marketConfigEmitter = new Subject<BidDeck[]>();

  apiUrl: string;

  constructor(private resourceService: ResourceService<any>) {
    this.apiUrl = resourceService.apiLocation;
  }

  putDayAheadConfig(site: Site, dayAheadMarket: DayAheadMarketConfig): Observable<DayAheadMarketConfig> {
    const payload = JSON.parse(JSON.stringify(dayAheadMarket))
    return this.resourceService.add(`sites/config/${site.id}`, payload);
  }

  putRealtimeConfig(site: Site, realTimeMarket: RealTimeMarketConfig): Observable<RealTimeMarketConfig> {
    const payload = {
      // riskProfileRt: (site.config['riskProfileRt']) ? site.config['riskProfileRt'] : 0,
      energyMarketRealTime: (site.config.energyMarketRealTime) ? site.config.energyMarketRealTime : true,
    };

    const mergedPayload = JSON.parse(JSON.stringify(Object.assign(payload, realTimeMarket)));
    return this.resourceService.add(`sites/config/${site.id}`, mergedPayload);
  }
}
