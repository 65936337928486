<div class="modal-header flex justify-between items-center">
  <h4 mat-dialog-title class="modal-title">Choose Export Time Range</h4>
  <button type="button" class="close x-btn x-btn-primary" aria-label="Close" (click)="dialogRef.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div mat-dialog-content class="modal-body">
  <div class="row flex items-stretch justify-start">
    <div class="section-left">
      <h3 class="modal-sub-header">Step 1: Choose Created Date </h3>
      <mat-calendar [(selected)]="selectedDate" #calendar (selectedChange)="getBidDeck($event)" class="modal-calendar"></mat-calendar>
      <div>
        <button class="x-btn x-btn-primary" (click)="selectToday()">Select Today</button>
      </div>
    </div>
    <div class="section-right">
      <h3 class="modal-sub-header">Step 2: Choose Data for export</h3>
      <div class="timestamp-container">
        <ng-container *ngFor="let timestamp of bidDeckTimeStamps; index as i">
          <div class="form-check">
            <input id="item-{{i}}" type="checkbox" (click)="addSelectedTimestamp(timestamp, $event)" />
            <label class="form-check-label" for="item-{{i}}">{{cleanMarketName(timestamp)}} {{timestamp.applicableTime | date:'medium'}} ({{toCTDate(timestamp.timestamp) | date:'medium'}})</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="modal-footer flex justify-end items-center">
  <button type="button" class="x-btn x-btn-primary" matTooltip="{{ !selectedDate ? 'You must select date in order to retrieve bid deck list.' : '' }}"
          [disabled]="!selectedDate || (selectedTimes.size == 0)" (click)="exportSelectedTimestamps()">Export Selected Items</button>
  <button type="button" class="x-btn x-btn-secondary" (click)="dialogRef.close()">Close</button>
</div>


