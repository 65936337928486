export interface EmsEvent {
  name: string;
  displayName: string;
  utcTimestamp: string;
  source: string;
  appliesTo: string;
  payload: string;
  payloadType: string;
  messageId: string;
  publisherVersion: number;
  payloadId: string;
  errorPayload: string;
}

export class EmsEvent {
  constructor() {
    this.name = "";
    this.displayName = "";
    this.utcTimestamp = "";
    this.source = "";
    this.appliesTo = "";
    this.payload = "";
    this.payloadType = "";
    this.messageId = "";
    this.publisherVersion = 0;
    this.payloadId = "";
    this.errorPayload = "";
  }
}
