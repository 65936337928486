import { Injectable } from "@angular/core";
import { GoogleModuleOptions } from "@ng-maps/google";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ResourceService } from "./resource.service";

@Injectable()
export class GoogleService implements GoogleModuleOptions {
  apiKey: string;
  libraries = ["places"];

  constructor(private resourceService: ResourceService<string>) {
  }

  load(): Observable<string> {
    return this.resourceService.get("googleapikey").pipe(
      tap(key => {
        const node = document.createElement("script");
        node.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;
        node.type = "text/javascript";
        document.getElementsByTagName("head")[0].appendChild(node);
        this.apiKey = key;
      })
    );
  }
}
