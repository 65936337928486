import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BidDeck, DayAheadMarketConfig, MarketTimeStamp, RealTimeMarketConfig, Site } from '@model';
import { ResourceService } from 'src/app/core/service/resource.service';

@Injectable({
  providedIn: 'root',
})
export class ForecastService {
  private readonly forecastTimestampEmitter = new Subject<MarketTimeStamp[]>();

  apiUrl: string;

  constructor(private resourceService: ResourceService<any>) {
    this.apiUrl = resourceService.apiLocation;
  }

  updateRtForecastSource(site: Site, forecastSource: string) {
    const payload = {
      rtForecaster: forecastSource,
    };

    return this.resourceService.update(`sites/${site.id}/config`, site.id, payload);
  }

  updateDaForecastSource(site: Site, forecastSource: string) {
    const payload = {
      daForecaster: forecastSource,
    };

    return this.resourceService.update(`sites/${site.id}/config`, site.id, payload);
  }

  getForecastTimestamps(site: Site, marketType: string, date: string): Observable<MarketTimeStamp[]> {
    return this.resourceService.getList(`sites/${site.id}/forecasts/${marketType}/timestamps/${date}`)
      .pipe(map(marketTimeStamps => {
      const timestamps: MarketTimeStamp[] = [];
      for (const marketTimeStamp of marketTimeStamps) {
        marketTimeStamp.type = 'Forecast';
        marketTimeStamp.displayLabel = 'Forecast: ' + marketTimeStamp.applicableTime;
        timestamps.push(marketTimeStamp);
      }

      return timestamps;
    }));
  }

  getForecastAsCsv(site: Site, marketType: string, applicableTime: string, timestamp: string) {
    return this.resourceService.get(`sites/${site.id}/forecasts/${marketType}/${applicableTime}/${timestamp}/csv`);
  }
}
