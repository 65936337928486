<div class="dialog-container" data-cy="bmd-dialog">
  <bmd-loader></bmd-loader>
  <div class="text-center">
    <h1 mat-dialog-title data-cy="dialog-title">{{ data.title }}</h1>
    <div mat-dialog-content>
      <p data-cy="dialog-content-text">{{ data.contentText }}</p>
    </div>
    <div mat-dialog-actions class="flex items-center justify-evenly">
      <button
        class="icon-btn secondary-btn"
        data-cy="dialog-first-button"
        matRipple
        (click)="data.firstButtonCallback()"
      >
        <span> {{ data.firstButtonText }}</span>
      </button>
      <button
        class="icon-btn primary-btn first-button"
        *ngIf="data.secondButtonText"
        data-cy="dialog-second-button"
        matRipple
        (click)="data.secondButtonCallback()"
      >
        <span> {{ data.secondButtonText }}</span>
      </button>
    </div>
  </div>
</div>
