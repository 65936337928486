import { Offer } from './offer.model';

export interface BidDeck {
  allowManualModification: boolean;
  allowPostprocessModification: boolean;
  comments: string;
  marketType: string;
  offers: Offer[];
}

export class BidDeck {
  constructor() {
    this.allowManualModification = false;
    this.allowPostprocessModification = false;
    this.comments = '';
    this.marketType = '';
    this.offers = new Array<Offer>();
  }
}
