import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AlertComponent } from "./alert.component";

@NgModule({
  declarations: [AlertComponent],
  exports: [AlertComponent],
  imports: [BrowserModule],
  bootstrap: [AlertComponent]
})
export class AlertModule {
}
