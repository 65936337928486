import { FormControl, FormGroup } from "@angular/forms";

export const isError = (control: FormControl): boolean => {
  return !!(control && control.invalid && (control.dirty || control.touched));
};

/**
 * Check if there is the error, if true the field will show the message
 * @param controlName: name of form control
 * @param errorName: name of the validation error that we are checking
 * @param form: formGroup or monthFormGroup
 */
export const check = (
  controlName: string,
  errorName: string,
  form: FormGroup
): boolean => {
  const abstractControl = form.get(controlName);
  if (
    abstractControl.invalid &&
    (abstractControl.dirty || abstractControl.touched)
  ) {
    if (abstractControl.errors?.[errorName]) {
      return true;
    }
  }
  return false;
};
