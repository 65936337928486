import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Crons } from '@model';
import { ResourceService } from 'src/app/core/service/resource.service';

@Injectable({
  providedIn: 'root',
})
export class CronService {
  private readonly cronEmitter = new Subject<Crons[]>();

  apiUrl: string;

  constructor(private resourceService: ResourceService<Crons>) {
    this.apiUrl = resourceService.apiLocation;
  }

  getCrons(siteId: string): Observable<Crons> {
    return this.resourceService.get(`sites/${siteId}/cron`);
  }
}
