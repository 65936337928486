import { Component, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import {
  RealTimeMarketConfig,
  ProgramConfig,
  DayAheadMarketConfig,
  DayAheadForecastOverrideConfig,
  RealTimeOverrideConfig,
  DayAheadMarketASPrices,
  DayAheadMarketCaps,
} from '@model';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bmd-hourly-config-modal',
  templateUrl: './hourly-config-modal.component.html',
  styleUrls: ['./hourly-config-modal.component.css'],
})
export class HourlyConfigModalComponent implements OnInit {
  @Input() fieldsToUpdate:
    | ProgramConfig
    | DayAheadForecastOverrideConfig
    | RealTimeOverrideConfig
    | DayAheadMarketASPrices
    | DayAheadMarketCaps;
  @Input() fieldLabels: string[];
  @Input() showError = false;
  @Input() headerText: string;
  @Input() subHeaderText: string;
  @Input() requireFields: boolean;
  @Output() savedConfig: EventEmitter<any> = new EventEmitter();

  saveDialogMessage = 'An error was detected while saving!';
  saveDialogColor = 'text-danger';
  saveDialogIcon = 'bi-exclamation-triangle';

  dynamicForm: FormGroup;
  formArray: FormArray;
  fieldNames: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<HourlyConfigModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialogConfig<any>,
  ) {
    this.formBuilder.group({
      theForm: [],
    });
  }

  ngOnInit() {
    // Default setup if never setup before

    // create a form array for the group
    this.formArray = new FormArray([]);
    this.fieldNames = Object.keys(this.fieldsToUpdate);

    // turn any strings to arrays
    Object.keys(this.fieldsToUpdate).map(field => {
      if (!Array.isArray(this.fieldsToUpdate[field])) {
        this.fieldsToUpdate[field] = this.maybeJsonParse(this.fieldsToUpdate[field]);
      }
    });

    for (let index = 0; index < 25; index++) {
      const group = this.formBuilder.group({});
      this.fieldNames.forEach(key => {
        const formControl = new FormControl(this.fieldsToUpdate[key][index], []);
        if (this.requireFields) {
          formControl.setValidators([Validators.required, Validators.min(0)]);
        }
        group.addControl(`${key}`, formControl);
      });

      this.formArray.push(group);
    }

    // create a top-level form
    this.dynamicForm = this.formBuilder.group({
      theForm: this.formArray,
    });
  }

  isNumber(numStr: string): boolean {
    return !isNaN(parseFloat(numStr)) && !isNaN(+numStr);
  }

  processClipboard() {
    navigator.clipboard
      .readText()
      .then(text => {
        let row_data = text.split(/\r\n|\r|\n/);

        // Analyze the selected text to see if it comes from a AS Price sheet
        // Expected format:
        // Row 0 header (optional)
        // Row 1..N: HEx\t{number}\t{number}\t{number}\t{number} OR {number}\t{number}\t{number}\t{number}
        let index = 0;

        row_data.forEach(row_Data => {
          let columns = row_Data.split('\t');

          // Skip columns until we encounter a number
          let startIndex = 0;
          let controlIndex = index;
          let hasHE = false;

          // Check if the previous column (if any) is an HE column >> in this case, use it as an index positioning
          if (columns[0].startsWith('HE 2*') || columns[0].startsWith('HE2*')) {
            controlIndex = 24;
            hasHE = true;
          } else if (columns[0].startsWith('HE')) {
            hasHE = true;
            let possibleIndex = parseInt(columns[startIndex].substring(2));
            if (!isNaN(possibleIndex)) {
              controlIndex = possibleIndex - 1;
            } else {
              // Cannot determine, better not screw up the grid, abort this row
              return;
            }
          }

          if (hasHE) {
            startIndex = 1;
          }

          // Check boundaries of found index
          if (controlIndex >= 0 && controlIndex < 25) {
            // Grab the index-th row of controls
            let formGroup: FormGroup = this.formArray.controls[controlIndex] as FormGroup;

            // Paste the values
            let fieldIndex = 0;
            for (let fieldName of this.fieldNames) {
              formGroup.get(fieldName).setValue(columns[startIndex + fieldIndex]);
              fieldIndex++;
            }
          }

          ++index;
        });
      })
      .catch(error => {
        console.error('Cannot read clipboard text: ', error);
      });
  }

  get theForm() {
    return this.dynamicForm?.get('theForm') as FormArray;
  }

  cloneRowEverywhere(index) {
    this.cloneRow(index, 0, 24);
  }

  cloneRowUp(index) {
    this.cloneRow(index, 0, index - 1);
  }

  cloneRowDown(index) {
    this.cloneRow(index, index + 1, 24);
  }

  cloneRow(sourceIndex: number, start: number, end: number) {
    const formGroup: FormGroup = this.formArray.controls[sourceIndex] as FormGroup;
    var index: number = 0;

    this.formArray.controls.forEach(fg => {
      if (index >= start && index <= end) {
        fg.patchValue(formGroup.value);
      }

      index++;
    });
  }

  updateEntity() {
    if (!window.confirm(`Are you sure you want to update?`)) {
      return;
    }

    // Update the grid
    for (let index = 0; index < 25; index++) {
      const formGroup: FormGroup = this.formArray.controls[index] as FormGroup;
      Object.keys(this.fieldsToUpdate).forEach(key => {
        this.fieldsToUpdate[key][index] = formGroup.get(key).value;
      });
    }

    this.savedConfig.emit(this.fieldsToUpdate);

    // Persist the data
    this.saveDialogMessage = 'Saving data, please wait...';
    this.saveDialogColor = 'text-primary';
    this.saveDialogIcon = 'bi-hourglass-split';
  }

  cancelForm() {
    if (!window.confirm(`Are you sure you want to cancel the changes?`)) {
      return;
    }
    this.savedConfig.emit(null);
    this.dialogRef.close('CANCEL');
  }
  maybeJsonParse(input: any) {
    try {
      return JSON.parse(input);
    } catch {
      return input;
    }
  }
}
