export interface MarketTimeStamp {
  applicableTime: string;
  marketName: string;
  siteId: string;
  timestamp: string;
  labelTitle?: string;
  type?: string;
}

export class MarketTimeStamp {
  constructor() {
    this.applicableTime = "";
    this.marketName = "";
    this.siteId = "";
    this.timestamp = "";
    this.labelTitle = "";
    this.type = "";
  }
}
