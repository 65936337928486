import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ResourceService} from './resource.service';
import {Configuration, MarketTimeStamp, Site} from '@model';

@Injectable()
export class SiteService {
  private allSites$: Observable<Site[]>;

  apiUrl: string;

  constructor(private siteResourceService: ResourceService<Site>,
              private timestampResourceService: ResourceService<MarketTimeStamp>,
              private eventResourceService: ResourceService<Event>) {
    this.apiUrl = siteResourceService.apiLocation;
  }

  getAllSites$(): Observable<Site[]> {
    this.allSites$ = this.siteResourceService.getList(`sites`).pipe(
      tap((sites) => {
        sites.forEach(site => site.spaceType = "Site");
      })
    );

    return this.allSites$;
  }

  postSiteConfig$(siteId: number | string, site: Configuration): Observable<Site> {
    return this.siteResourceService.add(`sites/config/${siteId}`, site);
  }

  getTimestamps(site: Site, marketType: string, date: string): Observable<MarketTimeStamp[]> {
    return this.timestampResourceService.getList(`sites/${site.id}/biddecks/${marketType}/timestamps/${date}`)
      .pipe(map(marketTimeStamps => {
      const timestamps = [];
      for (const marketTimeStamp of marketTimeStamps) {
        marketTimeStamp.type = 'Bid';
        marketTimeStamp.labelTitle = 'Bid: ' + marketTimeStamp.applicableTime;
        timestamps.push(marketTimeStamp);
      }

      return timestamps;
    }));
  }

  getSite(siteId): Observable<Site> {
    return new Observable<Site>(observer => {
      this.allSites$.subscribe(sites => observer.next(sites.find(site => site.id === siteId)));
    });
  }
}
