import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { SiteComponent } from "./site.component";
import { SiteHomeModule } from "src/app/view/site-home/site-home.module";
import { SiteConfigureModule } from "src/app/view/site-configure/site-configure.module";
import {CoreModule} from '@core';
import {SiteEventsModule} from '../site-events/site-events.module';

@NgModule({
  declarations: [SiteComponent],
  exports: [SiteComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatRippleModule,
    TranslateModule,
    MatTooltipModule,
    SiteHomeModule,
    SiteConfigureModule,
    SiteEventsModule,
    CoreModule,
  ],
})
export class SiteModule {
}
