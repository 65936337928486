import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { BMDComponent } from "@view";
import { RouterModule } from "@angular/router";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";

@NgModule({
  declarations: [BMDComponent],
  exports: [BMDComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    TranslateModule,
    MatTooltipModule,
    MatInputModule,
    RouterModule,
    MatMenuModule]
})
export class BMDModule {
}
