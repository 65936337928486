import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { take, tap } from "rxjs/operators";
import { ResourceService } from "./resource.service";
import { Locale } from "@model";

@Injectable()
export class LocalesService {
  locales$: Subject<Locale[]>;

  constructor(private resourceService: ResourceService<Locale>) {
    this.locales$ = new Subject<Locale[]>();
  }

  setLocales(): Observable<Locale[]> {
    return this.resourceService
      .getList("locales")
      .pipe(tap(locales => this.locales$.next(locales)));
  }

  checkForLocale(locale_key: string): Locale {
    const localeObservable = this.locales$.pipe(take(1));
    let locale: Locale;
    localeObservable.subscribe(locales => {
      locale = locales.find(
        localeIterated => localeIterated.localeName === locale_key
      );
    });
    return locale;
  }
}
