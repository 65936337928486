export interface Crons {
  dayAheadCron: string;
  realTimeCron: string;
}

export class Crons {
  constructor() {
    this.dayAheadCron = "";
    this.realTimeCron = "";
  }
}
