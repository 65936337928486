import { Component, Input, OnInit } from '@angular/core';
import { Crons, ProgramConfig, Site } from '@model';
import { SiteService } from '@service';

@Component({
  selector: 'bmd-site-configure',
  templateUrl: './site-configure.html',
  styleUrls: ['./site-configure.component.scss'],
})
export class SiteConfigureComponent implements OnInit {
  @Input() site: Site;
  @Input() crons: Crons;
  @Input() programConfig: ProgramConfig;
  expanded: boolean;

  constructor(private siteService: SiteService) {}

  ngOnInit() {
    this.expanded = true;
    // this.siteService.getSite(this.site?.id).subscribe(newSite => {
    //   this.site = newSite;
    // });
  }
}
