import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { EmsEvent, Site } from "@model";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { EventModalComponent } from "./event-modal/event-modal.component";
import * as moment from 'moment';
import 'moment-timezone';
import { interval } from "rxjs";
import {DialogService, EventService} from '@service';
import {MatExpansionPanel} from '@angular/material/expansion';
import {PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'bmd-site-events',
  templateUrl: './site-events.component.html',
  styleUrls: ['./site-events.component.scss'],
  viewProviders: [MatExpansionPanel]
})
export class SiteEventsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() site: Site
  private subject$: Subject<void>;
  events: EmsEvent[];
  pageEvent: PageEvent;
  expanded: true;
  length: number;
  page = 0;
  pageSize = 25;
  pageSizeOptions = [10, 25, 50]
  showFirstLast = true;
  refreshTime = 10000; // msec
  displayedColumns: string[] = ['info', 'timestamp', 'name'];
  eventRefreshInProgress = false;
  shortZone: string;
  public intervalRef;
  zoneAbbr;

  constructor(private eventService: EventService,
              public dialog: MatDialog) {}

  ngOnInit() {
    this.zoneAbbr = "";
    this.intervalRef = interval(this.refreshTime).subscribe(() => {
      return this.refreshSiteEvents();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.subject$ = new Subject<void>();
    this.refreshSiteEvents();
  }

  ngOnDestroy() {
    this.subject$.next();
    this.subject$.complete();
    this.intervalRef.unsubscribe();
  }

  // This syntax is required to pass into the interval function
  refreshSiteEvents = () => {
    if (this.site) {
      if (! this.eventRefreshInProgress) {
        this.eventRefreshInProgress = true
        this.eventService.getSiteRecentEvents(this.site.id)
          .pipe(
            map((events) => {
              for (let event of events) {
                // Documentation on the format string is here: https://momentjs.com/docs/#/displaying/format/
                event.utcTimestamp = moment.tz(event.utcTimestamp, "UTC").format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
                if (this.zoneAbbr == "") {
                  this.zoneAbbr = moment.tz(event.utcTimestamp, "UTC").tz(this.site.timezone, true).zoneAbbr();
                }
              }
              return events
            })
          )
          .subscribe(events => {
            this.events = events
            this.eventRefreshInProgress = false
          });
      }
    }
  }

  openEventModal(emsEvent: EmsEvent) {
    const dialogRef = this.dialog.open(EventModalComponent, { data: { size: 'lg'}});
    console.log("The dialog was opened");
    dialogRef.componentInstance.emsShallowEvent = emsEvent;
  }

  get filteredEvents(): EmsEvent[] {
    if (this.events) {
      return this.events
        .sort((a, b) => {
          return moment(b.utcTimestamp).valueOf() - moment(a.utcTimestamp).valueOf();
        })
        .map((events, i) => ({id: i + 1, ...events}))
        .slice((this.page) * this.pageSize, (this.page) * this.pageSize + this.pageSize);
    }

    return [];
  }

  handlePageEvent($event: PageEvent) {
    this.pageEvent = $event;
    this.pageSize = $event.pageSize;
    this.page = $event.pageIndex;
  }
}
