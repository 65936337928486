import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from './resource.service';
import { PriceLimits } from '@model';

@Injectable()
export class PriceLimitsService {
  private priceLimits$: Observable<PriceLimits>;

  apiUrl: string;

  constructor(private priceLimitsService: ResourceService<PriceLimits>) {
    this.apiUrl = priceLimitsService.apiLocation;
  }

  getPriceLimits(): Observable<PriceLimits> {
    this.priceLimits$ = this.priceLimitsService.get(`price-limits`);

    return this.priceLimits$;
  }
}
