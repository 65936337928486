export interface GlobalAlertOptions {
  type: "success" | "error";
  message: string;
  timeout: number;
}

export class GlobalAlertOptions {
  constructor(type: "success" | "error", message: string, timeout: number) {
    this.type = type;
    this.message = message;
    this.timeout = timeout;
  }
}
