<div>
    <div class="row justify-content-center">
        <button
            type="button"
            class="x-btn x-btn-primary"
            style="margin-bottom: 10px;"
            matTooltipPosition="above"
            matTooltip="Send a bid based on configuration below"
            (click)="createBidStrategy(false)"
            [disabled]="!hasAccess()"
        >
            Create Bid</button
        >&nbsp;
        <button
            type="button"
            class="x-btn x-btn-primary"
            style="margin-bottom: 10px;"
            matTooltipPosition="above"
            matTooltip="Generate a test bid based on configuration below"
            (click)="createBidStrategy(true)"
            [disabled]="!hasAccess()"
        >
            Create Test Bid</button
        >&nbsp;
        <button type="button" class="x-btn x-btn-secondary" style="margin-bottom: 10px;" (click)="exportData('apx')">
            Export APX</button
        >&nbsp;
        <button type="button" class="x-btn x-btn-secondary" style="margin-bottom: 10px;" (click)="exportData('csv')">
            Export CSV
        </button>
    </div>
    <hr />
    <div class="row form-group">
        <div class="label">Automated Bid Deck Creation Time:</div>
        <input type="text" class="w-full" readonly value="{{ cron }}" />
    </div>
    <hr />
    <form [formGroup]="dayAheadForm" ngNativeValidate>
        <div class="row form-group">
            <div class="col-12">
                <div class="label">Risk Profile:</div>
                <div class="flex flex-wrap">
                    <div class="form-check">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="use-hourly-risk-profile-da"
                            formControlName="useHourlyRiskProfileDayAhead"
                            (change)="updateUseHourlyRiskProfileDa(this.dayAheadForm.value)"
                        />
                        <label class="form-check-label" for="use-hourly-risk-profile-da"
                            >Use Hourly Risk Profiles</label
                        >
                    </div>
                    <div class="row justify-content-center form-group" *ngIf="showHourlyRiskProfileButton">
                        <button
                            type="button"
                            class="x-btn x-btn-primary"
                            style="margin-bottom: 10px;"
                            matTooltipPosition="above"
                            matTooltip="Set the hourly risk profiles"
                            (click)="openRiskProfilesModal(this.dayAheadForm.value)"
                            [disabled]="!hasAccess()"
                        >
                            Configure Hourly Risk Profiles
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row form-group" style="padding-left: 5%;" *ngIf="!showHourlyRiskProfileButton">
            <ngx-slider [(value)]="value" [options]="options" formControlName="riskProfileDayAhead">
                <ng-template #tooltipTemplate let-tooltip="tooltip" let-placement="placement" let-content="content">
                </ng-template>
            </ngx-slider>
        </div>

        <hr />
        <div class="row col-12 form-group flex flex-wrap">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="use-hourly-prices-da"
                    formControlName="useHourlyASPricesDayAhead"
                    (change)="updateUseHourlyASPricesDa(this.dayAheadForm.value)"
                />
                <label class="form-check-label" for="use-hourly-prices-da">Use Hourly AS Prices</label>
            </div>
            <div class="row col-12 justify-content-center form-group" *ngIf="showHourlyASPricesButton">
                <button
                    type="button"
                    class="x-btn x-btn-primary"
                    style="margin-top: 10px;"
                    matTooltipPosition="above"
                    matTooltip="Set the hourly max AS prices"
                    (click)="openASPricesModal(this.dayAheadForm?.value)"
                    [disabled]="!hasAccess()"
                >
                    Configure Hourly AS Prices
                </button>
            </div>
        </div>
        <br />
        <div class="row form-group flex justify-between items-stretch">
            <div class="col-7">
                <div class="row col-12">Bid on:</div>
                <div class="row col-12 form-group">
                    <div class="form-check">
                        <input
                            type="checkbox"
                            class="form-check-input disabled"
                            id="energyReadonly"
                            disabled="disabled"
                            checked="checked"
                        />
                        <label class="form-check-label disabled" for="energyReadonly">Energy</label>
                        <input type="hidden" id="energy" value="true" formControlName="energyMarketDayAhead" />
                    </div>
                </div>
                <div class="row col-12 form-group">
                    <div class="form-check gridded-check">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="reg-up-conventional"
                            formControlName="regUpMarketDayAhead"
                        />
                        <label class="form-check-label" for="reg-up-conventional">Regulation Up</label>
                    </div>
                </div>
                <div class="row col-12 form-group">
                    <div class="form-check gridded-check">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="reg-down-conventional"
                            formControlName="regDownMarketDayAhead"
                        />
                        <label class="form-check-label" for="reg-down-conventional">Regulation Down</label>
                    </div>
                </div>
                <div class="row col-12 form-group">
                    <div class="form-check gridded-check">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="responsive-reserve"
                            formControlName="rrsMarketDayAhead"
                        />
                        <label class="form-check-label" for="responsive-reserve">Responsive Reserve</label>
                    </div>
                </div>
                <div class="row col-12 form-group">
                    <div class="form-check gridded-check">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="ecrs"
                            formControlName="ecrsMarketDayAhead"
                        />
                        <label class="form-check-label" for="ecrs">ERCOT Contingency Reserve Service</label>
                    </div>
                </div>
                <div class="row col-12 form-group">
                    <div class="form-check gridded-check">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="nsrs"
                            formControlName="nsrsMarketDayAhead"
                        />
                        <label class="form-check-label" for="nsrs">Non Spinning Reserve Service</label>
                    </div>
                </div>
                <div class="row col-12 form-group">
                    <div class="form-check gridded-check">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            id="fast-frequency-response"
                            formControlName="ffrMarketDayAhead"
                        />
                        <label class="form-check-label" for="fast-frequency-response">Fast Frequency Response</label>
                    </div>
                </div>
            </div>

            <div class="col-5">
                <div class="row col-12 form-group">
                    <div *ngIf="!showHourlyASPricesButton">Bid Prices:</div>
                    <div *ngIf="showHourlyASPricesButton">Energy Bid Price:</div>
                    <input
                        type="number"
                        class="form-control"
                        formControlName="maxPriceEnergyDayAhead"
                        title="Max Energy DA Charge Price"
                    />
                </div>
                <div class="row col-12 form-group" *ngIf="!showHourlyASPricesButton">
                    <input type="number" class="form-control" formControlName="priceRegUp" title="Reg Up Price" />
                </div>
                <div class="row col-12 form-group" *ngIf="!showHourlyASPricesButton">
                    <input type="number" class="form-control" formControlName="priceRegDown" title="Reg Down Price" />
                </div>
                <div class="row col-12 form-group" *ngIf="!showHourlyASPricesButton">
                    <input type="number" class="form-control" formControlName="priceRrs" title="RRS Price" />
                </div>
                <div class="row col-12 form-group" *ngIf="!showHourlyASPricesButton">
                    <input type="number" class="form-control" formControlName="priceEcrs" title="ECRS Price" />
                </div>
                <div class="row col-12 form-group" *ngIf="!showHourlyASPricesButton">
                    <input type="number" class="form-control" formControlName="priceNsrs" title="NSRS Price" />
                </div>
            </div>
        </div>
        <hr />
        <div class="details-half-row">
            <div class="label" data-cy="site-da-forecast-source">
                {{ 'site.config.day_ahead_forecast_source' | translate }}:
            </div>
            <div id="site-da-forecast-source">
                <select formControlName="dayAheadForecaster">
                    <option [ngValue]="null">None Selected</option>
                    <option value="premo">Premo</option>
                    <!--          <option value="stochnet">StochNet</option>-->
                    <option value="veritone">Veritone</option>
                </select>
            </div>
        </div>
        <hr />
        <div class="details-half-row flex col-gap mb-8">
            <button
                type="button"
                class="x-btn x-btn-primary"
                style="margin-top: 10px;"
                matTooltipPosition="above"
                matTooltip="Day Ahead Program Config Forecast Overrides"
                (click)="openProgramConfigOverrides(this.programConfig)"
                [disabled]="!hasAccess()"
            >
                Configure AS Market Forecast Overrides
            </button>
            <button
                type="button"
                class="x-btn x-btn-primary"
                style="margin-top: 10px;"
                matTooltipPosition="above"
                matTooltip="Day Ahead Site Config Forecast Overrides"
                (click)="openSiteConfigOverrides(this.dayAheadForm?.value)"
                [disabled]="!hasAccess()"
            >
                Configure Site Forecast Overrides
            </button>
        </div>
    </form>

    <div class="row flex justify-end">
        <button
            type="button"
            class="x-btn x-btn-primary"
            (click)="save()"
            [disabled]="!hasAccess() || dayAheadForm.invalid || !dayAheadForm.dirty"
        >
            Save
        </button>
        &nbsp;
        <button
            type="button"
            class="x-btn x-btn-secondary"
            (click)="cancel()"
            [disabled]="!hasAccess() || dayAheadForm.invalid || !dayAheadForm.dirty"
        >
            Cancel
        </button>
    </div>
    <div class="row justify-end" *ngIf="!hasAccess()">
        <span class="disabled-reason">Save requires Admin privileges.</span>
    </div>
</div>
