import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { PermissionEnum, PricesToOffer, ProgramConfig, realTime, RealTimeMarketConfig, Site } from '@model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportModalComponent } from '../export-modal/export-modal.component';
import { Options } from '@angular-slider/ngx-slider';
import {
  BidService,
  BidStrategyService,
  ForecastService,
  GlobalAlertService,
  MarketsConfigService,
  PermissionService,
  SiteService,
} from '@service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { HourlyConfigModalComponent } from '../../shared/component/hourly-config-modal/hourly-config-modal.component';
import { RealTimeOverrideConfig } from '../../shared/model/real-time-market.model';

@Component({
  selector: 'bmd-rt-market',
  templateUrl: './real-time-market.component.html',
  styleUrls: ['./real-time-market.component.css'],
  viewProviders: [MatExpansionPanel],
})
export class RealTimeMarketComponent implements OnInit, OnDestroy, OnChanges {
  @Input() site: Site;
  @Input() cron: string;
  private subject$: Subject<void>;

  defaultPricesToOfferRealTime: PricesToOffer;
  pricesToOfferRealTime: PricesToOffer;

  realTimeForm = new FormGroup({
    maxPriceEnergyRealTime: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    nonSpinResponsibilityPrice: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    energyMarketRealTime: new FormControl(false),
    realTimeForecaster: new FormControl(''),
  });

  value: number = 0;
  riskProfileRealTime: number = 0;
  options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 0, legend: 'Low' },
      { value: 50, legend: 'Medium' },
      { value: 100, legend: 'High' },
    ],
  };

  constructor(
    private alertService: GlobalAlertService,
    private marketsConfigService: MarketsConfigService,
    private bidStrategyService: BidStrategyService,
    private permissionService: PermissionService,
    private siteService: SiteService,
    public dialog: MatDialog,
  ) {
    this.defaultPricesToOfferRealTime = {
      minPrice: 0,
      maxPrice: 105,
      nonSpin: 5000,
      strategy: 'taker',
      extraConf: { aggressivity: '', method: '' },
    };
  }

  ngOnInit() {
    this.subject$ = new Subject<void>();
    this.initForm();
  }

  // Comment out risk profile for real time, not being used yet per Dylan 09/21/2021.
  private initForm() {
    if (this.site) {
      this.pricesToOfferRealTime = this.site.config.pricesToOfferEnergyRealTime
        ? this.site.config.pricesToOfferEnergyRealTime
        : this.defaultPricesToOfferRealTime;
      this.riskProfileRealTime = this.site.config.riskProfileRealTime ? this.site.config.riskProfileRealTime : 0;

      this.realTimeForm = new FormGroup({
        maxPriceEnergyRealTime: new FormControl(
          this.pricesToOfferRealTime.maxPrice,
          Validators.compose([Validators.required, Validators.min(0)]),
        ),
        nonSpinResponsibilityPrice: new FormControl(
            this.pricesToOfferRealTime.nonSpin,
            Validators.compose([Validators.required, Validators.min(0)]),
        ),
        energyMarketRealTime: new FormControl(
          this.site.config.energyMarketRealTime && this.site.config.energyMarketRealTime === 'true',
        ),
        realTimeForecaster: new FormControl(this.site.config.realTimeForecaster),
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initForm();
  }

  ngOnDestroy() {
    this.subject$.next();
    this.subject$.complete();
  }

  save() {
    this.pricesToOfferRealTime.maxPrice = this.realTimeForm.get('maxPriceEnergyRealTime').value;
    this.pricesToOfferRealTime.nonSpin = this.realTimeForm.get('nonSpinResponsibilityPrice').value;

    let configuration: RealTimeMarketConfig = {
      energyMarketRealTime: this.realTimeForm.get('energyMarketRealTime').value,
      riskProfileRealTime: this.riskProfileRealTime,
      pricesToOfferEnergyRealTime: this.pricesToOfferRealTime,
      realTimeForecaster: this.realTimeForm.get('realTimeForecaster').value,
      hourlyIntraDayRtForecastOverride: this.realTimeForm.get('hourlyIntraDayRtForecastOverride')?.value,
    };

    this.marketsConfigService.putRealtimeConfig(this.site, configuration).subscribe(
      data => {
        this.alertService.setSuccess('Real time configuration has been saved successfully!');
        this.realTimeForm.markAsPristine();
      },
      error => {
        console.error(error);
        this.alertService.setError('An error was detected while saving real time market configuration.');
      },
    );
  }

  cancel() {
    this.initForm();
    this.realTimeForm.reset(this.realTimeForm.value);
  }

  exportData(type: string) {
    const dialogRef = this.dialog.open(ExportModalComponent, { data: { site: this.site, exportType: type } });
    dialogRef.componentInstance.exportType = realTime;
    dialogRef.componentInstance.site = this.site;
    dialogRef.componentInstance.csvType = type;
    console.log('the dialog was opened');
  }

  createBidStrategy(isTest: boolean) {
    const testString = isTest ? ' TEST' : '';

    if (!window.confirm(`Are you sure you want to send this${testString} bid strategy?`)) {
      return;
    }

    if (this.site) {
      this.bidStrategyService
        .sendRealTimeMarketBidStrategy(this.site, isTest)
        .pipe(takeUntil(this.subject$))
        .subscribe(
          data => {
            this.alertService.setSuccess(`Real time${testString} bid strategy has been requested.`);
          },
          error => {
            console.error(error);
            this.alertService.setError(`An error was detected while creating${testString} bid strategy.`);
          },
        );
    }
  }

  hasAccess(): boolean {
    return this.permissionService.hasPermission([PermissionEnum.update]);
  }

  openRTSPPForecast(realTimeMarket: RealTimeMarketConfig) {
    this.siteService.getSite(this.site.id).subscribe(site => {
      const modalReference = this.dialog.open(HourlyConfigModalComponent, {
        data: { size: 'lg', backdrop: 'static' },
      });
      this.site = site;
      modalReference.componentInstance.fieldsToUpdate = {
        hourlyIntraDayRtForecastOverride: this.site.config.hourlyIntraDayRtForecastOverride || [],
      } as RealTimeOverrideConfig;
      modalReference.componentInstance.fieldLabels = ['Intra-Day Real Time SPP Forecast Override'];
      modalReference.componentInstance.headerText = 'Real Time Forecast Overrides';
      modalReference.componentInstance.subHeaderText = '*Resets after elapsing';
      modalReference.componentInstance.requireFields = false;
      modalReference.componentInstance.savedConfig.subscribe(savedConfig => {
        if (savedConfig !== null) {
          realTimeMarket.hourlyIntraDayRtForecastOverride = savedConfig.hourlyIntraDayRtForecastOverride;

          this.marketsConfigService.putRealtimeConfig(this.site, savedConfig).subscribe(config => {
            this.site.config.hourlyIntraDayRtForecastOverride = savedConfig.hourlyRegUpForecastOverride;
            this.realTimeForm.markAsPristine();
            this.alertService.setSuccess('Real time configuration has been saved successfully!');
            modalReference.close();
          }),
            error => {
              console.error(error);
              this.alertService.setError('An error was detected while saving Site configuration.');
              modalReference.componentInstance.showError = true;
            };
        }
      });
    });
  }
}
