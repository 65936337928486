import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslateModule } from "@ngx-translate/core";
import { LoaderModule } from "../loader/loader.module";
import { DialogComponent } from "@component";

@NgModule({
  declarations: [DialogComponent],
  exports: [DialogComponent],
  imports: [
    MatDialogModule,
    TranslateModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatRippleModule,
    CommonModule,
    LoaderModule
  ]
})
export class DialogModule {
}
