import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Site } from "@model";

@Injectable()
export class TreeService {
  private _doNotChangeRoute = false;
  get doNotChangeRoute(): boolean {
    return this._doNotChangeRoute;
  }

  set doNotChangeRoute(value: boolean) {
    this._doNotChangeRoute = value;
  }

  private readonly selectSiteInListEmitter = new Subject<Site>();
  selectSiteInList$ = this.selectSiteInListEmitter.asObservable();

  private readonly deselectAllEmitter = new Subject<void>();

  private readonly enableFilterInputEmitter = new Subject<boolean>();
  enableFilterInput$ = this.enableFilterInputEmitter.asObservable();

  selectSiteInList(site: Site): void {
    this.selectSiteInListEmitter.next(site);
  }

  deselectAll(): void {
    this.deselectAllEmitter.next();
  }
}
