import { Injectable } from "@angular/core";
import { MatDrawerToggleResult, MatSidenav } from "@angular/material/sidenav";
import { Subject } from "rxjs";

@Injectable()
export class SidenavService {
  private sidenav: MatSidenav;
  private closeEmitter = new Subject();
  close$ = this.closeEmitter.asObservable();

  setSidenav(sidenav: MatSidenav): void {
    this.sidenav = sidenav;
  }

  open(): Promise<MatDrawerToggleResult> {
    return this.sidenav.open();
  }

  close(): Promise<MatDrawerToggleResult> {
    return this.sidenav.close();
  }

  closeIfMobile(): Promise<MatDrawerToggleResult> | void {
    if (this.sidenav.mode === "over") {
      this.closeEmitter.next(null);
    }
  }

  toggle(): void {
    this.sidenav.toggle();
  }
}
