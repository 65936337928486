import { Site } from './site.model';
import { PricesToOffer } from './prices-to-offer.model';

export interface DayAheadMarketConfig {
  regUpMarketDayAhead: boolean | string;
  regDownMarketDayAhead: boolean | string;
  frUpMarketDayAhead: boolean | string;
  frDownMarketDayAhead: boolean | string;
  rrsMarketDayAhead: boolean | string;
  ffrMarketDayAhead: boolean | string;
  ecrsMarketDayAhead: boolean | string;
  nsrsMarketDayAhead: boolean | string;
  nsrMarketDayAhead: boolean | string;
  useHourlyRiskProfileDayAhead: boolean | string;
  hourlyRegUpCapsDayAhead: number[];
  hourlyRegDownCapsDayAhead: number[];
  hourlyRrsCapsDayAhead: number[];
  hourlyEcrsCapsDayAhead: number[];
  hourlyNsrsCapsDayAhead: number[];
  hourlyRegUpPricesDayAhead: number[];
  hourlyRegDownPricesDayAhead: number[];
  hourlyRrsPricesDayAhead: number[];
  hourlyEcrsPricesDayAhead: number[];
  hourlyNsrsPricesDayAhead: number[];
  riskProfileDayAhead: number;
  energyMarketDayAhead: boolean | string;
  loadMarketDayAhead: boolean | string;
  useHourlyASPricesDayAhead: boolean | string;
  pricesToOfferEnergyDayAhead: PricesToOffer;
  pricesToOfferRegUp: PricesToOffer;
  pricesToOfferRegDown: PricesToOffer;
  pricesToOfferRRS: PricesToOffer;
  pricesToOfferECRS: PricesToOffer;
  pricesToOfferNsrs: PricesToOffer;
  dayAheadForecaster: string;
  hourlyDaForecastOverride: number[];
  hourlyNextDayRtForecastOverride: number[];
}

export interface DayAheadMarketConfigModalData {
  site: Site;
  exportType: string;
  csvType: string;
}

export class DayAheadMarketConfig {
  constructor() {
    this.regUpMarketDayAhead = false;
    this.regDownMarketDayAhead = false;
    this.frUpMarketDayAhead = false;
    this.frDownMarketDayAhead = false;
    this.rrsMarketDayAhead = false;
    this.ffrMarketDayAhead = false;
    this.ecrsMarketDayAhead = false;
    this.nsrsMarketDayAhead = false;
    this.nsrMarketDayAhead = false;
    this.useHourlyRiskProfileDayAhead = false;
    this.hourlyRegUpCapsDayAhead = [];
    this.hourlyRegDownCapsDayAhead = [];
    this.hourlyRrsCapsDayAhead = [];
    this.hourlyEcrsCapsDayAhead = [];
    this.hourlyNsrsCapsDayAhead = [];
    this.riskProfileDayAhead = 0;
    this.energyMarketDayAhead = false;
    this.loadMarketDayAhead = false;
    this.useHourlyASPricesDayAhead = false;
    this.dayAheadForecaster = '';
    this.hourlyDaForecastOverride = [];
    this.hourlyNextDayRtForecastOverride = [];
  }
}

export interface DayAheadMarketASPrices {
  hourlyRegUpPricesDayAhead: number[];
  hourlyRegDownPricesDayAhead: number[];
  hourlyRrsPricesDayAhead: number[];
  hourlyEcrsPricesDayAhead: number[];
  hourlyNsrsPricesDayAhead: number[];
}

export interface DayAheadMarketCaps {
  hourlyRegUpCapsDayAhead: number[];
  hourlyRegDownCapsDayAhead: number[];
  hourlyRrsCapsDayAhead: number[];
  hourlyEcrsCapsDayAhead: number[];
  hourlyNsrsCapsDayAhead: number[];
}
