import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { InlineEditModule } from "@component";
import {SiteEventsComponent} from './site-events.component';
import {EventModalComponent} from './event-modal/event-modal.component';
import {FormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  declarations: [SiteEventsComponent, EventModalComponent],
  exports: [SiteEventsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatSortModule,
    MatRippleModule,
    ClipboardModule,
    MatSnackBarModule,
    MatTooltipModule,
    InlineEditModule,
    FormsModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatTableModule,
    MatDialogModule
  ],
})
export class SiteEventsModule {
}
