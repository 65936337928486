export interface Offer {
  direction: string;
  energy: number;
  expirationDate: string;
  location: string;
  price: number;
  product: string;
  reason: string;
  tradingDay: string;
  tradingHour: number;
  unit: string;
}


export class Offer {
  constructor() {
    this.direction = "";
    this.energy = 0;
    this.expirationDate = "";
    this.location = "";
    this.price = 0;
    this.product = "";
    this.reason = "";
    this.tradingDay = "";
    this.tradingHour = 0;
    this.unit = "";
  }
}
