<table mat-table [dataSource]="filteredEvents" class="">
  <ng-container matColumnDef="info">
    <th mat-header-cell *matHeaderCellDef> Info </th>
    <td mat-cell *matCellDef="let element">
      <a class="v-align-mid" title="View Event" (click)="openEventModal(element)">
        <mat-icon aria-hidden="false" aria-label="View Event" class="text-primary-color">open_in_new</mat-icon>
      </a>
      {{element.displayName}}
    </td>
  </ng-container>

  <ng-container matColumnDef="timestamp">
    <th mat-header-cell *matHeaderCellDef> Timestamp </th>
    <td mat-cell *matCellDef="let element"> {{element.utcTimestamp | date:'medium' : zoneAbbr}}
      ({{site.timezone}}) </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div class="d-flex justify-content-between p-2" *ngIf="events">
  <mat-paginator
      [length]="events.length"
      [pageSizeOptions]="pageSizeOptions"
      aria-label="Select page"
      [pageSize]="pageSize"
      [showFirstLastButtons]="showFirstLast"
      [pageIndex]="page"
      (page)="handlePageEvent($event)"
  ></mat-paginator>
</div>
