export interface Locale {
  id: string;
  displayLabel: string;
  localeName: string;
}

export class Locale {
  constructor() {
    this.id = "1";
    this.displayLabel = "English (US)";
    this.localeName = "en_US";
  }
}
