import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslateModule } from "@ngx-translate/core";
import { BMDTreeComponent } from "src/app/shared/component/tree/bmd-tree.component";
import { TreeModule } from "enel-tree/dist/enel-tree";

@NgModule({
  declarations: [BMDTreeComponent],
  exports: [BMDTreeComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatRippleModule,
    TreeModule
  ]
})
export class BMDTreeModule {
}
