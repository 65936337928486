<div class="site-configure-details">
  <div class="app-section">
    <h4>{{ 'site.real_time_market' | translate}}</h4>
    <bmd-rt-market [site]="site" [cron]="crons?.realTimeCron"></bmd-rt-market>
  </div>

  <div class="app-section">
    <h4>{{ 'site.day_ahead_market' | translate}}</h4>
    <bmd-da-market [site]="site" [cron]="crons?.dayAheadCron" [programConfig]="programConfig"></bmd-da-market>
  </div>
</div>

<ng-template #emptySiteDerTable>
  <div class="subsection-container">
    <span class="ml-3">{{ 'site.der.no_data' | translate}}</span>
  </div>
</ng-template>
