import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { DashboardService } from "@service";

@Injectable()
export class DashboardResolver implements Resolve<string[]> {
  constructor(private dashboardService: DashboardService) {
  }

  resolve(): Observable<string[]> {
    return this.dashboardService.fakeCall$();
  }
}
