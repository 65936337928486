import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SiteConfigureComponent } from 'src/app/view/site-configure/site-configure.component';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SiteEventsModule } from '../site-events/site-events.module';
import { DayAheadMarketModule } from '../day-ahead-market/day-ahead-market.module';
import { RealTimeMarketModule } from '../real-time-market/real-time-market.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { HourlyConfigModalComponent } from '../../shared/component/hourly-config-modal/hourly-config-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [SiteConfigureComponent, HourlyConfigModalComponent],
  exports: [SiteConfigureComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    SiteEventsModule,
    DayAheadMarketModule,
    RealTimeMarketModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatDialogModule,
  ],
})
export class SiteConfigureModule {}
