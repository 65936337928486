import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Site } from '@model';
import { ResourceService } from 'src/app/core/service/resource.service';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BidStrategyService {
  private readonly cronEmitter = new Subject<any[]>();

  apiUrl: string;

  constructor(private resourceService: ResourceService<any>) {
    this.apiUrl = resourceService.apiLocation;
  }

  sendRealTimeMarketBidStrategy(site: Site, isTest: boolean) {
    const params = new HttpParams().set("test_bid", isTest);
    return this.resourceService.update(`sites/real-time`, site.id,{ name: site.name }, params);
  }

  sendDayAheadMarketBidStrategy(site: Site, isTest: boolean) {
    const params = new HttpParams().set("test_bid", isTest);
    return this.resourceService.update(`sites/day-ahead`, site.id, { name: site.name }, params);
  }
}
