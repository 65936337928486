import { Component, ViewEncapsulation } from "@angular/core";
import { LoaderService } from "@service";

@Component({
  selector: "bmd-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.css"],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class SpinnerComponent {
  constructor(public loader: LoaderService) {
  }
}
