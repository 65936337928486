export class CsvExportService {
  public static convertAndDownloadCsv(fileName: string, data: unknown) {
    const csv = encodeURI(CsvExportService.toCSVString(fileName, data));
    const download = document.createElement('a');
    download.setAttribute('href', csv);
    download.setAttribute('download', fileName);
    download.click();
  }

  private static toCSVString(fileName, data: unknown): string {
    return 'data:text/csv;charset=utf-8,' + data;
  }
}
