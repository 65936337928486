import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslateModule } from "@ngx-translate/core";
import { SpinnerComponent } from "src/app/shared/component/spinner/spinner.component";

@NgModule({
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatRippleModule
  ]
})
export class SpinnerModule {
}
