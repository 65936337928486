import { Component } from "@angular/core";

@Component({
  selector: "bmd-fitted-loader",
  templateUrl: "./fitted-loader.component.html",
  styleUrls: ["./fitted-loader.component.scss"]
})
export class FittedLoaderComponent {
  constructor() {
  }

}
