import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

const ICONSMAP = [
  "battery",
  "building1-white",
  "clear",
  "clear-pink",
  "delete",
  "edit",
  "edit-white",
  "electricity",
  "energy-storage",
  "export-data-white",
  "hospital-white",
  "load-profile",
  "map2",
  "manage-white",
  "no-data",
  "pillar-white",
  "reset",
  "reset-white",
  "reset-pink",
  "search-new",
  "tariff",
  "upload-new"
];

@Injectable()
export class IconsService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
  }

  load(): Promise<void> {
    return new Promise<void>(resolve => {
      ICONSMAP.forEach(ico => {
        this.iconRegistry.addSvgIcon(
          ico,
          this.sanitizer.bypassSecurityTrustResourceUrl(
            `/assets/svg-icons/${ico}.svg`
          )
        );
      });
      resolve();
    });
  }
}
