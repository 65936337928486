import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { GlobalAlertOptions } from "@model";

@Injectable()
export class GlobalAlertService {
  private messageEmitter = new Subject<GlobalAlertOptions>();
  message$ = this.messageEmitter.asObservable();

  setSuccess(message: string, timeout: number = 5000): void {
    const options = new GlobalAlertOptions("success", message, timeout);
    this.messageEmitter.next(options);
  }

  setError(message: string, timeout: number = 5000): void {
    const options = new GlobalAlertOptions("error", message, timeout);
    this.messageEmitter.next(options);
  }
}
