import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LoaderService } from "@service";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  permissionFirstTime = true;

  constructor(private loaderService: LoaderService, private router: Router) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      this.loaderService.excludedUiUrls.find(excludedUrl =>
        this.router.url.includes(excludedUrl)
      )
    ) {
      return next.handle(request);
    }
    for (const regexUrlItem of this.loaderService.getRegexEndpoints()) {
      if (regexUrlItem.test(request.url) && !this.permissionFirstTime) {
        return next.handle(request);
      }
    }

    /**
     * Ignore loader on welcome screen (path = / and there is no navigation)
     * but show it when we click on a project or
     * when we create a project (POST and PUT methods in creation)
     */
    if (
      location.pathname === "/" &&
      !this.router.getCurrentNavigation() &&
      request.method !== "PUT" &&
      request.method !== "POST"
    ) {
      return next.handle(request);
    }
    if (
      this.router.url.includes("tab=results") &&
      request.url.includes("results") &&
      !request.url.includes("results/export")
    ) {
      return next.handle(request);
    }
    const ignoreNext = this.loaderService.ignoreNext;
    if (ignoreNext && request.url.includes(ignoreNext)) {
      this.loaderService.setIgnoreNext(undefined);
      return next.handle(request);
    }
    if (request) {
      if (request.url.includes("permission")) {
        this.permissionFirstTime = false;
      }
      this.loaderService.start();
    }
    return next.handle(request).pipe(
      map(event => {
        if (event instanceof HttpResponse && request) {
          this.loaderService.end();
        }
        return event;
      })
    );
  }
}
