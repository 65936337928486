import { Component } from "@angular/core";
import { GlobalAlertOptions } from "@model";
import { GlobalAlertService } from "@service";

@Component({
  selector: "bmd-global-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"]
})
export class AlertComponent {
  isErrorAlertVisible: boolean;
  message: GlobalAlertOptions;

  constructor(private globalAlertService: GlobalAlertService) {
    this.isErrorAlertVisible = false;
    this.globalAlertService.message$.subscribe(message => {
      this.displayAlert(message);
    });
  }

  private displayAlert(message): void {
    this.message = message;
    this.isErrorAlertVisible = true;
    setTimeout(() => {
      this.isErrorAlertVisible = false;
    }, this.message.timeout);
  }

  get isError(): boolean {
    return this.message.type === "error";
  }

  get isSuccess(): boolean {
    return this.message.type === "success";
  }

  get messageClassName(): {
    "alert-success": boolean;
    "global-message": true;
    alert: true;
    "alert-danger": boolean;
  } {
    return {
      "global-message": true,
      alert: true,
      "alert-danger": this.isError,
      "alert-success": this.isSuccess
    };
  }

  get iconClassName(): {
    "fa-ban": boolean;
    icon: true;
    fa: true;
    "fa-check-circle": boolean;
  } {
    return {
      icon: true,
      fa: true,
      "fa-ban": this.isError,
      "fa-check-circle": this.isSuccess
    };
  }
}
