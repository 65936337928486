import { PricesToOffer } from './prices-to-offer.model';

export interface RealTimeMarketConfig {
  riskProfileRealTime: number;
  energyMarketRealTime: boolean;
  pricesToOfferEnergyRealTime: PricesToOffer;
  realTimeForecaster: string;
  hourlyIntraDayRtForecastOverride: number[];
}

export class RealTimeMarketConfig {
  constructor() {
    this.riskProfileRealTime = 0;
    this.energyMarketRealTime = false;
    this.realTimeForecaster = '';
    this.hourlyIntraDayRtForecastOverride = [];
  }
}

export interface RealTimeOverrideConfig {
  hourlyIntraDayRtForecastOverride: number[];
}
