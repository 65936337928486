import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GlobalNavModule } from "ngx-global-nav";
import { AppComponent } from "./app.component";
import { AlertModule, BMDTreeModule, LoaderModule } from "@component";
import { CoreModule } from "@core";
import { BMDModule } from "@view";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FormsModule } from "@angular/forms";
import { GlobalFooterModule } from "ngx-global-contact-footer/module";
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AlertModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    GlobalFooterModule.forRoot({ namespace: "ngx-global-contact-footer." }),
    GlobalNavModule.forRoot({ namespace: "ngx-global-nav." }),
    LoaderModule,
    MatSidenavModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    BMDModule,
    BMDTreeModule,
    MatSlideToggleModule,
    FormsModule,
    GlobalFooterModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule
  ],
  exports: [BrowserModule, BrowserAnimationsModule, CoreModule],
  bootstrap: [AppComponent]
})
export class AppModule {
}
