import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BidDeck, Site } from '@model';
import { ResourceService } from 'src/app/core/service/resource.service';

@Injectable({
  providedIn: 'root',
})
export class BidService {
  private readonly bidEmitter = new Subject<BidDeck[]>();

  apiUrl: string;

  constructor(private resourceService: ResourceService<any>) {
    this.apiUrl = resourceService.apiLocation;
  }

  getBidDeck(site: Site, applicableTime: string, timestampStr: string, marketType: string): Observable<BidDeck[]> {
    return this.resourceService.get(`sites/${site.id}/biddecks/${marketType}/${applicableTime}/${timestampStr}`);
  }

  getBidDeckAsCsv(site: Site, marketType: string, applicableTime: string, timestamp: string): Observable<BidDeck> {
    const csvType = 'csv'
    return this.resourceService.get(`sites/${site.id}/biddecks/${marketType}/${applicableTime}/${timestamp}/${csvType}`);
  }

  getBidDeckAsApxCsv(site: Site, marketType: string, applicableTime: string, timestamp: string): Observable<BidDeck> {
    const csvType = 'apxcsv'
    return this.resourceService.get(`sites/${site.id}/biddecks/${marketType}/${applicableTime}/${timestamp}/${csvType}`);
  }
}
