import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { PermissionService } from "@service";

@Injectable()
export class AuthGuard implements CanActivate {
  readonly forbiddenPath = "/forbidden/index.html";

  constructor(private userService: PermissionService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const { requiredPermissions } = route.data;
    const hasPermissions = this.userService.hasPermission(requiredPermissions);

    if (!hasPermissions) {
      document.location.href = document.location.origin + this.forbiddenPath;
      return false;
    } else {
      return true;
    }
  }
}
