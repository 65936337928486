import { Component, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
  selector: "bmd-component",
  templateUrl: "./bmd.html",
  styleUrls: ["./bmd.scss"]
})
export class BMDComponent implements OnDestroy {
  apiKey: string;
  subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }
}
