import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {BidDeck, DayAheadMarketConfig, EmsEvent, MarketTimeStamp, RealTimeMarketConfig, Site} from '@model';
import { ResourceService } from 'src/app/core/service/resource.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {

  apiUrl: string;

  constructor(private resourceService: ResourceService<any>) {
    this.apiUrl = resourceService.apiLocation;
  }

  updateEvents() {
    return this.resourceService.get(`load-events`, null);
  }

  getEvents() {
    return this.resourceService.get(`recent-events`, null);
  }

  getSiteRecentEvents(siteId: string) {
    return this.resourceService.get(`recent-events/site/${siteId}?fullDetails=false`)
  }

  getRecentEvent(messageId: string) : Observable<EmsEvent> {
    return this.resourceService.get(`recent-events/event/${messageId}?fullDetails=true`)
  }
}
