import { Injectable } from "@angular/core";
import { localeData, LongDateFormatKey } from "moment";
import * as moment from "moment-timezone";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { CookieService } from "./cookie.service";
import { ResourceService } from "./resource.service";
import { Timezone } from "@model";

@Injectable()
export class TimezonesService {
  timezones: Timezone[];
  private projectTimezones = new Map<string, string>();

  constructor(
    private cookieService: CookieService,
    private resourceService: ResourceService<Timezone>
  ) {
    this.timezones = [];
  }

  loadTimezones(): Observable<Timezone[]> {
    return this.resourceService.getList("timezones").pipe(
      tap(rawResp => {
        if (this.timezones && Array.isArray(this.timezones)) {
          while (this.timezones.length > 0) {
            this.timezones.pop();
          }
        }
        if (
          typeof rawResp !== undefined &&
          Array.isArray(rawResp) &&
          rawResp.length > 0
        ) {
          rawResp.sort(
            (a, b) => Number(a.displayLabel) - Number(b.displayLabel)
          );
          this.timezones.push(...rawResp);
        }
      })
    );
  }

  setTimezone(projectId: string, timezone: string): void {
    this.projectTimezones.set(projectId, timezone);
  }

  getTimezoneName(projectId: string): string {
    const projectTimezone = this.projectTimezones.get(projectId);
    const timezone = this.timezones.find(
      timezone => timezone.timezoneName === projectTimezone
    );
    return timezone?.timezoneName;
  }

  getTimezoneDisplayLabel(projectId: string): string {
    if (!this.timezones?.length) {
      return "-";
    }
    const projectTimezone = this.projectTimezones.get(projectId);
    const timezone = this.timezones.find(
      timezone => timezone.timezoneName === projectTimezone
    );
    return timezone?.displayLabel ?? "-";
  }

  getDateTimeDisplayLabel(
    projectId: string,
    date: string,
    longDateFormat: LongDateFormatKey = "lll"
  ): string {
    if (!date) {
      return "";
    }
    const timezoneName = this.getTimezoneName(projectId);
    const userLocale = localeData(
      this.adjustLocale(this.cookieService.getLocale())
    );
    let momentDate = moment(date);
    // If there is no timezone for any reason, stick with GMT Time
    if (timezoneName) {
      momentDate = momentDate.tz(timezoneName);
    }
    return momentDate.format(userLocale.longDateFormat(longDateFormat));
  }

  private adjustLocale(locale: string): string {
    return locale.toLowerCase().replace("_", "-");
  }
}
