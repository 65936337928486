  <div>
    <div class="row justify-content-center">
      <button type="button" class="x-btn, x-btn-primary" style="margin-bottom: 10px;" matTooltipPosition="above" matTooltip="Send a bid based on configuration below" [disabled]="!hasAccess()" (click)="createBidStrategy(false)">Create Bid</button>&nbsp;
      <button type="button" class="x-btn, x-btn-primary" style="margin-bottom: 10px;" matTooltipPosition="above" matTooltip="Generate a test bid based on configuration below" [disabled]="!hasAccess()" (click)="createBidStrategy(true)">Create Test Bid</button>&nbsp;
      <button type="button" class="x-btn, x-btn-secondary" style="margin-bottom: 10px;" (click)="exportData('apx')">Export APX</button>&nbsp;
      <button type="button" class="x-btn, x-btn-secondary" style="margin-bottom: 10px;" (click)="exportData('csv')">Export CSV</button>
    </div>
    <hr />
    <form [formGroup]="realTimeForm">
      <div class="row form-group">
        <div class="label">Automated Bid Deck Creation Time:</div>
        <input type="text" class="form-control w-full" readonly value="{{cron}}"/>
      </div>
      <hr />
      <div class="row form-group flex justify-between flex-wrap items-stretch">
        <div class="col-6">
          <div class="label">Bid on:</div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input disabled" id="energyRtReadonly" disabled="disabled" checked="checked">
            <label class="form-check-label disabled" for="energyRtReadonly">Energy</label>
            <input type="hidden" id="energy" value="true" formControlName="energyMarketRealTime">
          </div>
        </div>
        <div class="col-6">
          <div class="label">Bid Price:</div>
          <input type="number" class="form-control" formControlName="maxPriceEnergyRealTime" title="Max Energy Real Time Charge Price" />
        </div>
      </div>
      <div class="row form-group flex justify-end flex-wrap items-stretch">
        <div class="col-6">
          <div class="label">Non-Spin Resp. Price:</div>
          <input type="number" class="form-control"
                 matTooltip="Non-Spin Resp. Price should be at least $75/MWh and also greater than Bid Price"
                 formControlName="nonSpinResponsibilityPrice" title="Non-Spin Responsibility Price" />
        </div>
      </div>
      <hr />
        <div class="details-row">
            <div class="details-half-row">
                 <div class="label" data-cy="site-rt-forecase-source">{{ 'site.config.real_time_forecast_source' | translate }}:</div>
                <div id="site-rt-forecast-source">
                    <select formControlName="realTimeForecaster">
                        <option [ngValue]="null">None Selected</option>
                        <option value="baseline">Baseline</option>
                        <option value="premo">Premo</option>
                        <option value="veritone">Veritone</option>
                    </select>
                </div>
            </div>
        </div>
        <hr />
        <div class="details-row">
            <div class="details-half-row flex mb-8">
                <button type="button" class="x-btn x-btn-primary" style="margin-top: 10px;" matTooltipPosition="above" matTooltip="Configure Real Time Forecast Overrides" (click)="openRTSPPForecast(this.realTimeForm?.value)" [disabled]="!hasAccess()">Configure Site Forecast Overrides</button>
            </div>
        </div>
    </form>

      <div class="row flex justify-end">
        <button type="button" class="x-btn x-btn-primary btn-bottom" (click)="save()" [disabled]="!hasAccess() || realTimeForm.invalid || !realTimeForm.dirty">Save</button> &nbsp;
        <button type="button" class="x-btn x-btn-secondary btn-bottom" (click)="cancel()" [disabled]="!hasAccess() || realTimeForm.invalid || !realTimeForm.dirty">Cancel</button>
      </div>

  </div>
