<div class="full p-12 flex">
  <div class="full flex flex-col justify-end items-start left">
    <div class="bmd-welcome flex flex-col">
      <span class="welcome-text text-light-color" data-cy="welcome-title-1"
            [translate]="'welcome.part1'"></span>
      <span class="welcome-text text-primary-color bmd-dashboard"
            data-cy="welcome-title-2">{{ "welcome.part2" | translate }}</span>

      <div data-cy="info-container"
           class="background-primary flex justify-between mt-1 p-8 pt-1 pb-12 text-container">
        <div class="mr-10">
          <p data-cy="updates-title" class="sub-header">{{ "welcome.latest_updates" | translate }}</p>
          <div data-cy="updates" class="updates">
            <span *ngFor="let update of updates">{{ update }}</span>
          </div>
        </div>
        <div data-cy="quick-links">
          <p class="sub-header">{{ "welcome.quick_links" | translate }}</p>
          <p class="link"
             (click)="goTo('https://confluence.springlab.enel.com/display/DERPM/BMD+Dashboard')">{{ "welcome.confluence_page" | translate }}</p>
        </div>
      </div>
    </div>
    <div data-cy="dashboard-image" class="cropped pr-20">
    </div>
  </div>
</div>
