export const environment = {
  production: true,
  brands: {
    calibrant: {
      baseUrl: '/',
    },
  },
  defaults: {
    brand: 'calibrant',
    locale: 'en-us',
  },
  base_url: 'https://bmd-api.der.enelx.com/bmd/',
  x_api_key: 'iXQ0MVdUSE8k72rETVbaH273BMROzJPq33T1EhWc',
};
