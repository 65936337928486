export interface Timezone {
  id: string;
  displayLabel: string;
  timezoneName: string;
}

export class Timezone {
  constructor() {
    this.id = "26";
    this.displayLabel = "(GMT-05:00) Eastern Time (US & Canada)";
    this.timezoneName = "America/New_York";
  }
}
