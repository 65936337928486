import * as yaml from 'js-yaml';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {EmsEvent} from "@model";
import {EventService} from '@service';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'bmd-risk-profiles-modal',
  templateUrl: './event-modal.component.html',
  styleUrls: ['./event-modal.component.scss'],
})
export class EventModalComponent implements OnInit {

  @Input() emsShallowEvent: EmsEvent;
  emsEvent: EmsEvent;
  payload: {};
  loadingEvent: boolean;
  constructor(
    public dialogRef: MatDialogRef<EventModalComponent>,
    private eventService: EventService,
    @Inject(MAT_DIALOG_DATA) public data: MatDialogConfig<any>
) { }

  ngOnInit() {
    this.loadingEvent = true
    this.loadEvent()
  }

  loadEvent = () => {
    this.eventService.getRecentEvent(this.emsShallowEvent.messageId).subscribe(event => {
      // TODO: manage error if event not in cache or invalid emsEventId
      this.emsEvent = event
      this.loadingEvent = false

      // For errors, payload is a string, not JSON
      if (this.emsEvent.name == 'texmark_error.discovered') {
        this.payload = '<No payload>';
      } else {
        const payloadObj = JSON.parse(this.emsEvent.payload);
        if (payloadObj) {
          this.payload = yaml.dump(payloadObj, {'quotingType': '"'});
          if (EventModalComponent.isBidSubmittedEvent(this.emsEvent.name)) {
            // Special case for bid submitted: Show bid deck at the end
            this.payload += '\n---- Bid Deck ----\n';
            const bidObj = JSON.parse(payloadObj.bidDeck);
            this.payload += yaml.dump(bidObj, {'quotingType': '"'});
          }
        } else {
          this.payload = '<No payload>';
        }
      }
    })
  }

  private static isBidSubmittedEvent(eventName: string): boolean {
    return eventName == 'texmark_rt_bid.submitted' || eventName == 'texmark_da_bid.submitted';
  }
}
