<div class="flex flex-col justify-start sidenav-body" *ngIf="!loading">
  <div class="table-container" *ngIf="sites?.length">
    <enel-tree-display
        [treeData]="treeData"
        [expandable]="expandable"
        [showSearch]=false
        [showControls]=false
        [allowSelectAllResults]="selectAllResults"
        [allowDeselectAll]="allowDeselectAll"
        [cascadeSelect]=false
        [expandAll]=false
        [maxSelections]="maxSelections"
        [typeIdentifier]="disabledNodeAttribute"
        [labelIdentifier]="labelIdentifier"
        [iconDefinitions]="iconMap"
        [iconIdentifier]="iconIdentifier"
        [disabledNodeDefinitions]="disabledNodeDefinitions"
        [searchProperties]="searchProperties"
        (expansion$)="expansionHandler($event)"
        (selection$)="selectionHandler($event)"
        (clicked$)="clickedHandler($event)">
    </enel-tree-display>
  </div>
  <div class="list-empty" *ngIf="!loading && !sites?.length">
    {{ 'sidenav.list.none_configured' | translate }}
  </div>
</div>
<div class="spinner-container" *ngIf="loading">
  <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
</div>
