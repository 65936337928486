import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { dayAhead, DayAheadMarketConfig, PermissionEnum, PricesToOffer, ProgramConfig, Site } from '@model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ExportModalComponent } from '../export-modal/export-modal.component';
import { Options } from '@angular-slider/ngx-slider';
import {
  BidService,
  BidStrategyService,
  ForecastService,
  GlobalAlertService,
  MarketsConfigService,
  PermissionService,
  ProgramService,
  SiteService,
} from '@service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { HourlyConfigModalComponent } from '../../shared/component/hourly-config-modal/hourly-config-modal.component';

@Component({
  selector: 'bmd-da-market',
  templateUrl: './day-ahead-market.component.html',
  styleUrls: ['./day-ahead-market.component.css'],
  viewProviders: [MatExpansionPanel],
})
export class DayAheadMarketComponent implements OnInit, OnDestroy, OnChanges {
  @Input() site: Site;
  @Input() cron: string;
  @Input() programConfig: ProgramConfig;
  showHourlyRiskProfileButton: boolean | string = false;
  showHourlyASPricesButton: boolean | string = false;
  value: number = 0;
  riskProfileDayAhead: number = 0;
  options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 0, legend: 'Low' },
      { value: 50, legend: 'Medium' },
      { value: 100, legend: 'High' },
    ],
  };
  private subject$: Subject<void>;

  dayAheadForm: FormGroup = new FormGroup({
    regUpMarketDayAhead: new FormControl(false),
    useHourlyRiskProfileDayAhead: new FormControl(false),
    useHourlyASPricesDayAhead: new FormControl(false),
    regDownMarketDayAhead: new FormControl(false),
    // EMS-1017: these settings are not used. Conservatively keeping the code around in case they become useful
    // frupMarketDayAhead: new FormControl((this.site.config.frUpMarketDayAhead) ? this.site.config.frUpMarketDayAhead : false),
    // frdownMarketDayAhead: new FormControl((this.site.config.frDownmarketDayAhead) ? this.site.config.frDownmarketDayAhead : false),
    rrsMarketDayAhead: new FormControl(false),
    ffrMarketDayAhead: new FormControl(false),
    ecrsMarketDayAhead: new FormControl(false),
    nsrsMarketDayAhead: new FormControl(false),
    nsrMarketDayAhead: new FormControl(false),
    riskProfileDayAhead: new FormControl(0),
    energyMarketDayAhead: new FormControl(false),
    loadMarketDayAhead: new FormControl(false),
    dayAheadForecaster: new FormControl(''),
    maxPriceEnergyDayAhead: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    priceRegUp: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    priceRegDown: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    priceRrs: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    priceEcrs: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
    priceNsrs: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
  });

  defaultPricesToOfferDayAhead: PricesToOffer;
  pricesToOfferDayAhead: PricesToOffer;

  defaultPricesToOfferAS: PricesToOffer;
  pricesToOfferRegUp: PricesToOffer;
  pricesToOfferRegDown: PricesToOffer;
  pricesToOfferRrs: PricesToOffer;
  pricesToOfferEcrs: PricesToOffer;
  pricesToOfferNsrs: PricesToOffer;

  constructor(
    private alertService: GlobalAlertService,
    private marketsConfigService: MarketsConfigService,
    private programService: ProgramService,
    private bidService: BidService,
    private forecastService: ForecastService,
    private bidStrategyService: BidStrategyService,
    private permissionService: PermissionService,
    private siteService: SiteService,
    public dialog: MatDialog,
  ) {
    this.defaultPricesToOfferDayAhead = {
      minPrice: 0,
      maxPrice: 105,
      nonSpin: null,
      strategy: 'taker',
      extraConf: { aggressivity: '', method: '' },
    };
    this.defaultPricesToOfferAS = {
      minPrice: 10,
      maxPrice: 200,
      nonSpin: null,
      strategy: 'taker',
      extraConf: { aggressivity: '', method: '' },
    };
  }

  ngOnInit() {
    this.subject$ = new Subject<void>();
    this.initForm();
  }

  private initForm() {
    if (this.site && this.site.config) {
      this.pricesToOfferDayAhead = this.site.config.pricesToOfferEnergyDayAhead
        ? this.site.config.pricesToOfferEnergyDayAhead
        : this.defaultPricesToOfferDayAhead;
      this.pricesToOfferRegUp = this.site.config.pricesToOfferRegUp
        ? this.site.config.pricesToOfferRegUp
        : this.defaultPricesToOfferAS;
      this.pricesToOfferRegDown = this.site.config.pricesToOfferRegDown
        ? this.site.config.pricesToOfferRegDown
        : this.defaultPricesToOfferAS;
      this.pricesToOfferRrs = this.site.config.pricesToOfferRrs
        ? this.site.config.pricesToOfferRrs
        : this.defaultPricesToOfferAS;
      this.pricesToOfferEcrs = this.site.config.pricesToOfferEcrs
        ? this.site.config.pricesToOfferEcrs
        : this.defaultPricesToOfferAS;
      this.pricesToOfferNsrs = this.site.config.pricesToOfferNsrs
        ? this.site.config.pricesToOfferNsrs
        : this.defaultPricesToOfferAS;

      this.dayAheadForm = new FormGroup({
        regUpMarketDayAhead: new FormControl(
          this.site.config.regUpMarketDayAhead && this.site.config.regUpMarketDayAhead === 'true',
        ),
        useHourlyRiskProfileDayAhead: new FormControl(
          this.site.config.useHourlyRiskProfileDayAhead && this.site.config.useHourlyRiskProfileDayAhead === 'true',
        ),
        useHourlyASPricesDayAhead: new FormControl(
          this.site.config.useHourlyAsPricesDayAhead && this.site.config.useHourlyAsPricesDayAhead === 'true',
        ),
        regDownMarketDayAhead: new FormControl(
          this.site.config.regDownMarketDayAhead && this.site.config.regDownMarketDayAhead === 'true',
        ),
        // EMS-1017: these settings are not used. Conservatively keeping the code around in case they become useful
        // frupMarketDayAhead: new FormControl((this.site.config.frUpMarketDayAhead) ? this.site.config.frUpMarketDayAhead : false),
        // frdownMarketDayAhead: new FormControl((this.site.config.frDownmarketDayAhead) ? this.site.config.frDownmarketDayAhead : false),
        rrsMarketDayAhead: new FormControl(
          this.site.config.rrsMarketDayAhead && this.site.config.rrsMarketDayAhead === 'true',
        ),
        ffrMarketDayAhead: new FormControl(
          this.site.config.ffrMarketDayAhead && this.site.config.ffrMarketDayAhead === 'true',
        ),
        ecrsMarketDayAhead: new FormControl(
          this.site.config.ecrsMarketDayAhead && this.site.config.ecrsMarketDayAhead === 'true',
        ),
        nsrsMarketDayAhead: new FormControl(
          this.site.config.nsrsMarketDayAhead && this.site.config.nsrsMarketDayAhead === 'true',
        ),
        nsrMarketDayAhead: new FormControl(
          this.site.config.nsrMarketDayAhead && this.site.config.nsrMarketDayAhead === 'true',
        ),
        riskProfileDayAhead: new FormControl(
          this.site.config.riskProfileDayAhead ? this.site.config.riskProfileDayAhead : 0,
        ),
        energyMarketDayAhead: new FormControl(
          this.site.config.energyMarketDayAhead && this.site.config.energyMarketDayAhead === 'true',
        ),
        loadMarketDayAhead: new FormControl(
          this.site.config.loadMarketDayAhead && this.site.config.loadMarketDayAhead === 'true',
        ),
        maxPriceEnergyDayAhead: new FormControl(
          this.pricesToOfferDayAhead.maxPrice,
          Validators.compose([Validators.required, Validators.min(0)]),
        ),
        priceRegUp: new FormControl(
          this.pricesToOfferRegUp.minPrice,
          Validators.compose([Validators.required, Validators.min(0)]),
        ),
        priceRegDown: new FormControl(
          this.pricesToOfferRegDown.minPrice,
          Validators.compose([Validators.required, Validators.min(0)]),
        ),
        priceRrs: new FormControl(
          this.pricesToOfferRrs.minPrice,
          Validators.compose([Validators.required, Validators.min(0)]),
        ),
        priceEcrs: new FormControl(
          this.pricesToOfferEcrs.minPrice,
          Validators.compose([Validators.required, Validators.min(0)]),
        ),
        priceNsrs: new FormControl(
          this.pricesToOfferNsrs.minPrice,
          Validators.compose([Validators.required, Validators.min(0)]),
        ),
        dayAheadForecaster: new FormControl(this.site.config.dayAheadForecaster),
      });
      //this.riskProfileDa = (this.site.config.riskProfileDayAhead) ? this.site.config.riskProfileDayAhead : 0;

      this.showHourlyRiskProfileButton =
        this.site.config.useHourlyRiskProfileDayAhead && this.site.config.useHourlyRiskProfileDayAhead === 'true';
      this.showHourlyASPricesButton =
        this.site.config.useHourlyAsPricesDayAhead && this.site.config.useHourlyAsPricesDayAhead === 'true';
    }
  }

  updateUseHourlyRiskProfileDa(dayAheadMarket: DayAheadMarketConfig) {
    this.showHourlyRiskProfileButton = dayAheadMarket.useHourlyRiskProfileDayAhead;
  }

  updateUseHourlyASPricesDa(dayAheadMarket: DayAheadMarketConfig) {
    this.showHourlyASPricesButton = dayAheadMarket.useHourlyASPricesDayAhead;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initForm();
  }

  ngOnDestroy() {
    this.subject$.next();
    this.subject$.complete();
  }

  save() {
    this.pricesToOfferDayAhead.maxPrice = this.dayAheadForm.get('maxPriceEnergyDayAhead').value;
    this.pricesToOfferRegUp.minPrice = this.dayAheadForm.get('priceRegUp').value;
    this.pricesToOfferRegDown.minPrice = this.dayAheadForm.get('priceRegDown').value;
    this.pricesToOfferRrs.minPrice = this.dayAheadForm.get('priceRrs').value;
    this.pricesToOfferEcrs.minPrice = this.dayAheadForm.get('priceEcrs').value;
    this.pricesToOfferNsrs.minPrice = this.dayAheadForm.get('priceNsrs').value;

    var configuration: DayAheadMarketConfig = {
      regUpMarketDayAhead: this.dayAheadForm.get('regUpMarketDayAhead').value === true,
      useHourlyRiskProfileDayAhead: this.dayAheadForm.get('useHourlyRiskProfileDayAhead').value === true,
      useHourlyASPricesDayAhead: this.dayAheadForm.get('useHourlyASPricesDayAhead').value === true,
      regDownMarketDayAhead: this.dayAheadForm.get('regDownMarketDayAhead').value === true,
      frUpMarketDayAhead: this.site.config.frUpMarketDayAhead === true,
      frDownMarketDayAhead: this.site.config.frDownMarketDayAhead === true,
      rrsMarketDayAhead: this.dayAheadForm.get('rrsMarketDayAhead').value === true,
      ffrMarketDayAhead: this.dayAheadForm.get('ffrMarketDayAhead').value === true,
      ecrsMarketDayAhead: this.dayAheadForm.get('ecrsMarketDayAhead').value === true,
      nsrsMarketDayAhead: this.dayAheadForm.get('nsrsMarketDayAhead').value === true,
      nsrMarketDayAhead: this.dayAheadForm.get('nsrMarketDayAhead').value === true,
      energyMarketDayAhead: this.dayAheadForm.get('energyMarketDayAhead').value === true,
      loadMarketDayAhead: this.dayAheadForm.get('loadMarketDayAhead').value === true,
      riskProfileDayAhead: this.dayAheadForm.get('riskProfileDayAhead').value,
      pricesToOfferEnergyDayAhead: this.pricesToOfferDayAhead,
      pricesToOfferRegUp: this.pricesToOfferRegUp,
      pricesToOfferRegDown: this.pricesToOfferRegDown,
      pricesToOfferRRS: this.pricesToOfferRrs,
      pricesToOfferECRS: this.pricesToOfferEcrs,
      pricesToOfferNsrs: this.pricesToOfferNsrs,

      hourlyRegUpCapsDayAhead: this.site.config.hourlyRegUpCapsDayAhead
        ? this.site.config.hourlyRegUpCapsDayAhead
        : null,
      hourlyRegDownCapsDayAhead: this.site.config.hourlyRegDownCapsDayAhead
        ? this.site.config.hourlyRegDownCapsDayAhead
        : null,
      hourlyRrsCapsDayAhead: this.site.config.hourlyRrsCapsDayAhead ? this.site.config.hourlyRrsCapsDayAhead : null,
      hourlyEcrsCapsDayAhead: this.site.config.hourlyEcrsCapsDayAhead ? this.site.config.hourlyEcrsCapsDayAhead : null,
      hourlyNsrsCapsDayAhead: this.site.config.hourlyNsrsCapsDayAhead ? this.site.config.hourlyNsrsCapsDayAhead : null,
      hourlyRegUpPricesDayAhead: this.site.config.hourlyRegUpPricesDayAhead
        ? this.site.config.hourlyRegUpPricesDayAhead
        : null,
      hourlyRegDownPricesDayAhead: this.site.config.hourlyRegDownPricesDayAhead
        ? this.site.config.hourlyRegDownPricesDayAhead
        : null,
      hourlyRrsPricesDayAhead: this.site.config.hourlyRrsPricesDayAhead
        ? this.site.config.hourlyRrsPricesDayAhead
        : null,
      hourlyEcrsPricesDayAhead: this.site.config.hourlyEcrsPricesDayAhead
        ? this.site.config.hourlyEcrsPricesDayAhead
        : null,
      hourlyNsrsPricesDayAhead: this.site.config.hourlyNsrsPricesDayAhead
        ? this.site.config.hourlyNsrsPricesDayAhead
        : null,

      hourlyDaForecastOverride: this.site.config.hourlyDaForecastOverride || null,
      hourlyNextDayRtForecastOverride: this.site.config.hourlyNextDayRtForecastOverride || null,

      dayAheadForecaster: this.dayAheadForm.get('dayAheadForecaster').value,
    };

    this.marketsConfigService.putDayAheadConfig(this.site, configuration).subscribe(
      data => {
        this.alertService.setSuccess('Day ahead configuration has been saved successfully!');
        this.dayAheadForm.markAsPristine();
      },
      error => {
        console.error(error);
        this.alertService.setError('An error was detected while saving day ahead market configuration.');
      },
    );
  }

  cancel() {
    this.initForm();
    this.dayAheadForm.reset(this.dayAheadForm.value);
  }

  exportData(type: string) {
    const dialogRef = this.dialog.open(ExportModalComponent, { data: { site: this.site, exportType: type } });
    dialogRef.componentInstance.exportType = dayAhead;
    dialogRef.componentInstance.site = this.site;
    dialogRef.componentInstance.csvType = type;
    console.log('the dialog was opened');
  }

  createBidStrategy(isTest: boolean) {
    const testString = isTest ? ' TEST' : '';
    if (!window.confirm(`Are you sure you want to send this${testString} bid strategy?`)) {
      return;
    }

    if (this.site) {
      this.bidStrategyService
        .sendDayAheadMarketBidStrategy(this.site, isTest)
        .pipe(takeUntil(this.subject$))
        .subscribe(
          data => {
            this.alertService.setSuccess(`Day ahead${testString} bid strategy has been requested.`);
          },
          error => {
            console.error(error);
            this.alertService.setError(`An error was detected while creating${testString} bid strategy.`);
          },
        );
    }
  }

  openRiskProfilesModal(dayAheadMarket: DayAheadMarketConfig) {
    const modalReference = this.dialog.open(HourlyConfigModalComponent, { data: { size: 'lg', backdrop: 'static' } });

    modalReference.componentInstance.fieldsToUpdate = {
      hourlyRegUpCapsDayAhead: this.site.config.hourlyRegUpCapsDayAhead || [],
      hourlyRegDownCapsDayAhead: this.site.config.hourlyRegDownCapsDayAhead || [],
      hourlyRrsCapsDayAhead: this.site.config.hourlyRrsCapsDayAhead || [],
      hourlyEcrsCapsDayAhead: this.site.config.hourlyEcrsCapsDayAhead || [],
      hourlyNsrsCapsDayAhead: this.site.config.hourlyNsrsCapsDayAhead || [],
    };
    modalReference.componentInstance.fieldLabels = [
      'REGUP Cap %',
      'REGDOWN Cap %',
      'RRS Cap %',
      'ECRS Cap %',
      'NSRS Cap %',
    ];
    modalReference.componentInstance.headerText = 'Hourly Risk Profiles';
    modalReference.componentInstance.subHeaderText = '';
    modalReference.componentInstance.requireFields = true;

    modalReference.componentInstance.savedConfig.subscribe(savedConfig => {
      if (savedConfig != null) {
        dayAheadMarket.hourlyRegUpCapsDayAhead = savedConfig.hourlyRegUpCapsDayAhead;
        dayAheadMarket.hourlyRegDownCapsDayAhead = savedConfig.hourlyRegDownCapsDayAhead;
        dayAheadMarket.hourlyRrsCapsDayAhead = savedConfig.hourlyRrsCapsDayAhead;
        dayAheadMarket.hourlyEcrsCapsDayAhead = savedConfig.hourlyEcrsCapsDayAhead;
        dayAheadMarket.hourlyNsrsCapsDayAhead = savedConfig.hourlyNsrsCapsDayAhead;

        this.marketsConfigService.putDayAheadConfig(this.site, dayAheadMarket).subscribe(() => {
          // Update site settings in case we reopen the dialog
          this.site.config.hourlyRegUpCapsDayAhead = savedConfig.hourlyRegUpCapsDayAhead;
          this.site.config.hourlyRegDownCapsDayAhead = savedConfig.hourlyRegDownCapsDayAhead;
          this.site.config.hourlyRrsCapsDayAhead = savedConfig.hourlyRrsCapsDayAhead;
          this.site.config.hourlyEcrsCapsDayAhead = savedConfig.hourlyEcrsCapsDayAhead;
          this.site.config.hourlyNsrsCapsDayAhead = savedConfig.hourlyNsrsCapsDayAhead;
          // Clear the dirty flag
          this.dayAheadForm.markAsPristine();
          modalReference.close();
          this.alertService.setSuccess('Day ahead configuration has been saved successfully!');
        }),
          error => {
            console.error(error);
            this.alertService.setError('An error was detected while saving site configuration.');
            modalReference.componentInstance.showError = true;
          };
      }
    });
  }

  openASPricesModal(dayAheadMarket: DayAheadMarketConfig) {
    const modalReference = this.dialog.open(HourlyConfigModalComponent, { data: { size: 'lg', backdrop: 'static' } });

    modalReference.componentInstance.fieldsToUpdate = {
      hourlyRegUpPricesDayAhead: this.site.config.hourlyRegUpPricesDayAhead || [],
      hourlyRegDownPricesDayAhead: this.site.config.hourlyRegDownPricesDayAhead || [],
      hourlyRrsPricesDayAhead: this.site.config.hourlyRrsPricesDayAhead || [],
      hourlyEcrsPricesDayAhead: this.site.config.hourlyEcrsPricesDayAhead || [],
      hourlyNsrsPricesDayAhead: this.site.config.hourlyNsrsPricesDayAhead || [],
    };
    modalReference.componentInstance.fieldLabels = [
      'REGUP Price',
      'REGDOWN Price',
      'RRS Price',
      'ECRS Price',
      'NSRS Price',
    ];
    modalReference.componentInstance.headerText = 'Day Ahead AS Prices';
    modalReference.componentInstance.subHeaderText = '';
    modalReference.componentInstance.requireFields = true;

    modalReference.componentInstance.savedConfig.subscribe(savedConfig => {
      if (savedConfig != null) {
        dayAheadMarket.hourlyRegUpPricesDayAhead = savedConfig.hourlyRegUpPricesDayAhead;
        dayAheadMarket.hourlyRegDownPricesDayAhead = savedConfig.hourlyRegDownPricesDayAhead;
        dayAheadMarket.hourlyRrsPricesDayAhead = savedConfig.hourlyRrsPricesDayAhead;
        dayAheadMarket.hourlyEcrsPricesDayAhead = savedConfig.hourlyEcrsPricesDayAhead;
        dayAheadMarket.hourlyNsrsPricesDayAhead = savedConfig.hourlyNsrsPricesDayAhead;

        this.marketsConfigService.putDayAheadConfig(this.site, dayAheadMarket).subscribe(() => {
          // Update site settings in case we reopen the dialog
          this.site.config.hourlyRegUpPricesDayAhead = savedConfig.hourlyRegUpPricesDayAhead;
          this.site.config.hourlyRegDownPricesDayAhead = savedConfig.hourlyRegDownPricesDayAhead;
          this.site.config.hourlyRrsPricesDayAhead = savedConfig.hourlyRrsPricesDayAhead;
          this.site.config.hourlyEcrsPricesDayAhead = savedConfig.hourlyEcrsPricesDayAhead;
          this.site.config.hourlyNsrsPricesDayAhead = savedConfig.hourlyNsrsPricesDayAhead;
          // Clear the dirty flag
          this.dayAheadForm.markAsPristine();
          modalReference.close();
          this.alertService.setSuccess('Day ahead configuration has been saved successfully!');
        }),
          error => {
            console.error(error);
            this.alertService.setError('An error was detected while saving site configuration.');
            modalReference.componentInstance.showError = true;
          };
      }
    });
  }

  hasAccess(): boolean {
    return this.permissionService.hasPermission([PermissionEnum.update]);
  }

  openSiteConfigOverrides(marketConfig: DayAheadMarketConfig) {
    this.siteService.getSite(this.site.id).subscribe(site => {
      const modalReference = this.dialog.open(HourlyConfigModalComponent, {
        data: { size: 'lg', backdrop: 'static' },
      });

      modalReference.componentInstance.fieldsToUpdate = {
        hourlyDaForecastOverride: site.config.hourlyDaForecastOverride || [],
        hourlyNextDayForecastOverride: site.config.hourlyNextDayRtForecastOverride || [],
      };

      modalReference.componentInstance.fieldLabels = [
        'Day Ahead LMP Forecast Override',
        'Next-Day Real Time SPP Forecast Override',
      ];
      modalReference.componentInstance.headerText = 'Day Ahead Site Forecast Overrides';
      modalReference.componentInstance.subHeaderText = '*Resets after market closure';
      modalReference.componentInstance.requireFields = false;
      modalReference.componentInstance.savedConfig.subscribe(savedConfig => {
        if (savedConfig !== null) {
          marketConfig.hourlyDaForecastOverride = savedConfig.hourlyDaForecastOverride;
          marketConfig.hourlyNextDayRtForecastOverride = savedConfig.hourlyNextDayForecastOverride;

          this.marketsConfigService.putDayAheadConfig(this.site, marketConfig).subscribe(() => {
            this.site.config.hourlyDaForecastOverride = savedConfig.hourlyDaForecastOverride;
            this.site.config.hourlyNextDayRtForecastOverride = savedConfig.hourlyNextDatForecastOverride;
            this.dayAheadForm.markAsPristine();
            modalReference.close();
            this.alertService.setSuccess('Day ahead configuration has been saved successfully!');
          }),
            error => {
              console.error(error);
              this.alertService.setError('An error was detected while saving site configuration.');
              modalReference.componentInstance.showError = true;
            };
        }
      });
    });
  }

  openProgramConfigOverrides(programConfig: ProgramConfig) {
    const modalReference = this.dialog.open(HourlyConfigModalComponent, {
      data: { size: 'lg', backdrop: 'static' },
    });
    modalReference.componentInstance.fieldsToUpdate = programConfig;
    modalReference.componentInstance.fieldLabels = [
      'REGUP Forecast Override',
      'REGDOWN Forecast Override',
      'RRS Forecast Override',
      'ECRS Forecast Override',
      'NSRS Forecast Override',
    ];
    modalReference.componentInstance.headerText = 'Day Ahead AS Market Forecast Overrides';
    modalReference.componentInstance.subHeaderText = '*Changes will apply for ALL sites.  Resets after market closure.';
    modalReference.componentInstance.requireFields = false;
    modalReference.componentInstance.savedConfig.subscribe(savedConfig => {
      if (savedConfig !== null) {
        this.programService.putProgramConfig$(savedConfig).subscribe(config => {
          this.programConfig.hourlyRegUpForecastOverride = savedConfig.hourlyRegUpForecastOverride;
          this.programConfig.hourlyRegDownForecastOverride = savedConfig.hourlyRegDownForecastOverride;
          this.programConfig.hourlyRrsForecastOverride = savedConfig.hourlyRrsForecastOverride;
          this.programConfig.hourlyEcrsForecastOverride = savedConfig.hourlyEcrsForecastOverride;
          this.programConfig.hourlyNsrsForecastOverride = savedConfig.hourlyNsrsForecastOverride;
          this.dayAheadForm.markAsPristine();
          modalReference.close();
          this.alertService.setSuccess('Day ahead configuration has been saved successfully!');
        }),
          error => {
            console.error(error);
            this.alertService.setError('An error was detected while saving program configuration.');
            modalReference.componentInstance.showError = true;
          };
      }
    });
  }
}
