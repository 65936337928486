export interface Location {
  address: string;
  latitude: number;
  longitude: number;
  vppSiteConfigurationFactor: number;
  enableForecasts: boolean;
  commissioned: boolean;
  utility: string;
  deliveryAfter: string;
  deliveryBefore: string;
}

export class Location {
  constructor();
  constructor(address: string, lat: number, lng: number);
  constructor(...args: any[]) {
    if (!args?.length) {
      this.address = null;
      this.latitude = null;
      this.longitude = null;
      this.vppSiteConfigurationFactor = null;
      this.enableForecasts = false;
      this.commissioned = false;
      this.utility = null;
      this.deliveryAfter = null;
      this.deliveryBefore = null;
    }
    if (args?.length === 9) {
      this.address = args[0];
      this.latitude = args[1];
      this.longitude = args[2];
      this.vppSiteConfigurationFactor = args[3];
      this.enableForecasts = args[4];
      this.commissioned = args[5];
      this.utility = args[6];
      this.deliveryAfter = args[7];
      this.deliveryBefore = args[8];
    }
  }
}
