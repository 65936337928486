import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { CookieService } from "@service";

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.cookieService.getCookie("mock_mode")) {
      console.log(
        `Intercepted Http Request: "${request.method} ${request.url}"`
      );
      const response: HttpResponse<unknown> = new HttpResponse({});
      return of(response);
    } else {
      return next.handle(request);
    }
  }
}
