import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { MarketTimeStamp, Site, dayAhead, realTime } from '@model';
import {BidService, ForecastService, SiteService} from '@service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DayAheadMarketConfigModalData} from '../../shared/model/day-ahead-market-config.model';
import {CsvExportService} from '../../core/service/csv-export.service';
import { MatDatepicker } from '@angular/material/datepicker';


@Component({
  selector: 'bmd-export-modal',
  styleUrls: ['./export-modal.component.scss'],
  templateUrl: './export-modal.component.html'
})
export class ExportModalComponent implements OnInit, OnDestroy {
  bidDeckTimeStamps: MarketTimeStamp[] = [];
  selectedTimes: Set<MarketTimeStamp> = new Set([]);
  forecastTimestamps: MarketTimeStamp[] = [];
  zoneAbbr: string;
  private subject$: Subject<void>;
  date: {year: number, month: number};
  site: Site;
  csvType: string;
  exportType: string;
  selectedDate: Date;

  @ViewChild(MatDatepicker) datepicker: MatDatepicker<Date>

  constructor(
              private formBuilder: FormBuilder,
              private siteService: SiteService,
              private forecastService: ForecastService,
              private bidService: BidService,
              public dialogRef: MatDialogRef<ExportModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DayAheadMarketConfigModalData,
              ) {
    this.site = data.site;
    this.exportType = data.exportType;
    this.csvType = data.csvType;
  }

  ngOnInit(): void {
    this.subject$ = new Subject<void>();
    this.zoneAbbr = '';
  }

  ngOnDestroy(): void {
    this.subject$.next();
    this.subject$.complete();
  }

  selectToday() {
    this.selectedDate = new Date(Date.now())
    this.getBidDeck(this.selectedDate);
  }

  cleanMarketName(item: MarketTimeStamp): string {
    if (item.type === 'Forecast') {
      return 'Price Forecast';
    } else {
      if (item.marketName === realTime) {
        return 'Real Time Bid';
      }

      if (item.marketName === dayAhead) {
        return 'Day Ahead Bid';
      }
    }
    return '';
  }

  getBidDeck(dateEvent: Date): void {
    this.selectedTimes.clear();
    const month = (dateEvent.getMonth() < 9) ? '0' + (dateEvent.getMonth() + 1).toString() : dateEvent.getMonth() + 1;
    const day = (dateEvent.getDate() < 10) ? '0' + dateEvent.getDate() : dateEvent.getDate();
    const year = dateEvent.getFullYear();
    const inputDate = `${year}-${month}-${day}`
    forkJoin([
      this.siteService.getTimestamps(this.site, this.exportType, inputDate),
      this.forecastService.getForecastTimestamps(this.site, this.exportType, inputDate)
    ]).pipe(takeUntil(this.subject$)).subscribe(data => {
        this.bidDeckTimeStamps = data[0].concat(data[1]);
    });
  }

  toCTDate(utcTimestamp: string): string {
    return new Date(utcTimestamp + 'Z').toLocaleString('en-US', {timeZone: 'America/Chicago'});
  }

  addSelectedTimestamp(timestamp: MarketTimeStamp, $event) {
    if ($event?.target?.checked) {
      this.selectedTimes.add(timestamp)
    } else {
      this.selectedTimes.delete(timestamp);
    }
  }

  exportSelectedTimestamps() {
    const csvRequests: any[] = [];
    const fileNames = [];

    for (const marketTimeStamp of this.selectedTimes.values()) {
      if (marketTimeStamp.type === 'Bid') {
        if (this.csvType === 'apx') {
          csvRequests.push(
            this.bidService.getBidDeckAsApxCsv(
              this.site, marketTimeStamp.marketName, marketTimeStamp.applicableTime, marketTimeStamp.timestamp)
          );
        } else {
          csvRequests.push(
            this.bidService.getBidDeckAsCsv(
              this.site, marketTimeStamp.marketName, marketTimeStamp.applicableTime, marketTimeStamp.timestamp)
          );
        }
      }

      if (marketTimeStamp.type === 'Forecast') {
        csvRequests.push(
          this.forecastService.getForecastAsCsv(
            this.site, marketTimeStamp.marketName, marketTimeStamp.applicableTime, marketTimeStamp.timestamp)
        );
      }

      fileNames.push(`${((marketTimeStamp.marketName === realTime) ? 'Real_Time' : 'Day_Ahead')}-${marketTimeStamp.type}_${marketTimeStamp.applicableTime}-${marketTimeStamp.timestamp}.csv`);
    }

    forkJoin(csvRequests).pipe(takeUntil(this.subject$)).subscribe(csvResponses => {
      // tslint:disable-next-line:forin
      for (const csvResponseIdx in csvResponses) {
        CsvExportService.convertAndDownloadCsv(fileNames[csvResponseIdx], csvResponses[csvResponseIdx]);
      }
    });
  }
}
