import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { HomeComponent } from "src/app/view/home/home.component";
import { MatTableModule } from "@angular/material/table";
import { RouterModule } from "@angular/router";
import { MatStepperModule } from "@angular/material/stepper";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [HomeComponent],
  exports: [HomeComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatSortModule,
    MatRippleModule,
    ClipboardModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatTableModule,
    RouterModule,
    MatStepperModule,
    MatButtonModule
  ]
})
export class HomeModule {
}
